import { forwardRef, MutableRefObject, useEffect, useImperativeHandle, useRef } from 'react';
import './Pptx2video.scss';
import { useTranslation } from "react-i18next";
import {
  Button,
  Input,
  Flex,
  Text,
  Progress,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { IoClose } from "react-icons/io5";
import { FiUpload } from "react-icons/fi";
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as CONSTANTS from '../../constants';
import { apiPptx2video } from '../../helpers/api'
import store from '../../store';
import { CustomAlertDialog } from '../CustomAlert/CustomAlert'
import { UploadMediaDo } from '../../helpers/upload'
import { baseApiParams } from 'my-api-client-package';
import { DownloadMedia } from '../../helpers/download'

export const RightSettingSide = forwardRef((props, ref) => {
  const inputPptxRef: MutableRefObject<any> = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef: MutableRefObject<any> = React.useRef()

  useImperativeHandle(ref, () => ({
    resetAll,
    audioScriptGeneration,
    interrupthandling
  }));

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const convertingProgress = useSelector((state: any) => state.convertingProgress);
  const pptxFile = useSelector((state: any) => state.pptxFile);
  const fileName = useSelector((state: any) => state.fileName);
  const slideCount = useSelector((state: any) => state.slideCount);
  const convert = useSelector((state: any) => state.convert);
  const llmRun = useSelector((state: any) => state.llmRun);
  // const isConvertDisabled = useSelector((state: any) => state.isConvertDisabled);
  const id = useSelector((state: any) => state.id);
  const thumbnailId = useSelector((state: any) => state.thumbnailId);
  const thumbnailUrl = useSelector((state: any) => state.thumbnailUrl);
  const language = useSelector((state: any) => state.language);
  const resolution = useSelector((state: any) => state.resolution);
  const notesIncluded = useSelector((state: any) => state.notesIncluded);
  const specification = useSelector((state: any) => state.specification);
  // const pdfFiles = useSelector((state: any) => state.pdfFiles);

  const sibling_scripts = useSelector((state: any) => state.sibling_scripts);

  let currentSlide = 0

  // const isConvertDisabledRef = useRef(isConvertDisabled);
  // useEffect(() => {
  //   isConvertDisabledRef.current = isConvertDisabled;
  // }, [isConvertDisabled]);

  const thumbnailUrlRef = useRef(thumbnailUrl);
  useEffect(() => {
    thumbnailUrlRef.current = thumbnailUrl;
  }, [thumbnailUrl]);

  const handlePptxUploadButtonClick = () => {
    if (inputPptxRef.current) {
      inputPptxRef.current.click();
    }
  };

  const cleanPptxMeta = () => {
    dispatch({ type: CONSTANTS.SET_CONVERTING_PROGRESS, payload: -1 });
    dispatch({ type: CONSTANTS.SET_PPTX_FILE, payload: null });
    dispatch({ type: CONSTANTS.SET_FILE_NAME, payload: '' });
    dispatch({ type: CONSTANTS.SET_CONVERT, payload: '' });
    dispatch({ type: CONSTANTS.SET_LLM_RUN, payload: '' });
    dispatch({ type: CONSTANTS.SET_SLIDE_COUNT, payload: -1 });
    dispatch({ type: CONSTANTS.SET_ID, payload: '' });
    dispatch({ type: CONSTANTS.SET_THUMBNAIL_ID, payload: '' });
    dispatch({ type: CONSTANTS.SET_THUMBNAIL_URL, payload: '' });
    dispatch({ type: CONSTANTS.SET_SIBLING_SCRIPTS, payload: {} });
    dispatch({ type: CONSTANTS.SET_EDITABLE_SCRIPTS, payload: {} });
    dispatch({ type: CONSTANTS.SET_MEMORY_SCRIPTS, payload: {} });
    dispatch({ type: CONSTANTS.SET_CURRENT_SLIDE, payload: [-1, -1] });
    dispatch({ type: CONSTANTS.SET_EDIT_HISTORY, payload: {} });
    currentSlide = 0
  }

  const handlePptxUpload = (event: any) => {
    cleanPptxMeta()
    dispatch({ type: CONSTANTS.SET_CONVERT, payload: t('smartslide.uploading_state') });
    const file = event.target.files[0];
    dispatch({ type: CONSTANTS.SET_PPTX_FILE, payload: file });
  };

  useEffect(() => {
    if (pptxFile && convert === t('smartslide.uploading_state')) {
      parserPptx(pptxFile)
    }
  }, [pptxFile, convert]);

  useEffect(() => {
    const fetchThumbnail = async () => {
      try {
        const thumbnailUrl = await DownloadMedia(thumbnailId);
        const updatedState = store.getState();
        if (!updatedState.isConvertDisabled) {
          dispatch({ type: CONSTANTS.SET_THUMBNAIL_URL, payload: thumbnailUrl });
        }
        else {
          dispatch({ type: CONSTANTS.SET_THUMBNAIL_URL, payload: '' });
        }
      } catch (error) {
        console.error('Error fetching thumbnail:', error);
      }
      await new Promise(resolve => setTimeout(resolve, 0));
    };

    if (thumbnailId) {
      fetchThumbnail();
    } else {
      dispatch({ type: CONSTANTS.SET_THUMBNAIL_URL, payload: '' });
    }
  }, [thumbnailId]);

  // const UploadMediaDo = async (file: File) => {
  //   const mediaId = (await (await apiMedia.pptx2Video.pptxUploadCreate({ file: file }, baseApiParams())).json())['pptx_id']
  //   return mediaId
  // }

  function parseResolution(resolution: string): { w: number; h: number } | { w: 1920, h: 1080 } {
    const resolutions: { [key: string]: { w: number; h: number } } = {
      "1080p": { w: 1920, h: 1080 },
      "720p": { w: 1280, h: 720 },
      "480p": { w: 854, h: 480 },
      "360p": { w: 640, h: 360 },
      "240p": { w: 426, h: 240 }
    };
    return resolutions[resolution] || { w: 1920, h: 1080 };
  }

  const parserPptx = async (file: File) => {
    dispatch({ type: CONSTANTS.SET_IS_CONVERT_DISABLED, payload: false });
    dispatch({ type: CONSTANTS.SET_CONVERTING_PROGRESS, payload: -2 }); // uploading
    const name: any = file.name;
    dispatch({ type: CONSTANTS.SET_FILE_NAME, payload: name.slice(0, name.lastIndexOf('.')) || name });
    const newId = await UploadMediaDo(file)
    dispatch({ type: CONSTANTS.SET_ID, payload: newId });
    let count = -1
    try {
      const headers = baseApiParams()
      headers['headers']['server'] = process.env.REACT_APP_MEDIA_SERVER
      const countAndThumbnail = (await (await apiPptx2video.pptx2Video.slideSumDetail(newId, headers)).json())
      count = countAndThumbnail['number']
      dispatch({ type: CONSTANTS.SET_SLIDE_COUNT, payload: count });
      dispatch({ type: CONSTANTS.SET_THUMBNAIL_ID, payload: countAndThumbnail['thumbnail'] });
    } catch (error) {
      console.error("Error fetching slide count:", error);
    }
    dispatch({ type: CONSTANTS.SET_CONVERTING_PROGRESS, payload: 0 });
    dispatch({ type: CONSTANTS.SET_CONVERT, payload: t('smartslide.running_state') });
    let parser_result: any = {}
    for (let slide = 1; slide <= count; slide++) {
      const updatedState = store.getState();
      if (updatedState.isConvertDisabled) {
        dispatch({ type: CONSTANTS.SET_CONVERT, payload: t('smartslide.interrupted_state') });
        cleanPptxMeta()
        break
      }

      const resol = parseResolution('1080p');

      const data = {
        "pptx_id": newId,
        "slide_index": slide,
        "slide_sum": count,
        "width": resol.w,
        "height": resol.h,
      }
      const headers = baseApiParams()
      headers['headers']['server'] = process.env.REACT_APP_MEDIA_SERVER
      const thread_id = (await (await apiPptx2video.pptx2Video.slideParserCreate(data, headers)).json())['thread_id']

      let isCompleted = false;
      while (!isCompleted) {
        const headers = baseApiParams()
        headers['headers']['server'] = process.env.REACT_APP_MEDIA_SERVER
        const state = (await (await apiPptx2video.pptx2Video.statusDetail(thread_id, headers)).json())['message'];

        if (state === 'Running') {
          console.log('running');
        } else if (state === 'Completed') {
          const headers = baseApiParams()
          headers['headers']['server'] = process.env.REACT_APP_MEDIA_SERVER
          const result = (await (await apiPptx2video.pptx2Video.resultDetail(thread_id, headers)).json())['message'];
          parser_result[String(currentSlide)] = result
          console.log(parser_result)
          currentSlide += 1;
          isCompleted = true;
        } else {
          console.log(`error: ${state}`);
          isCompleted = true;
        }

        if (!isCompleted) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }

      smoothUpdateProgress(Math.floor((slide / count) * 100))

      if (slide === count) {
        // dispatch({ type: CONSTANTS.SET_CONVERTING_PROGRESS, payload: 100 });
        dispatch({ type: CONSTANTS.SET_CONVERT, payload: t('smartslide.success_state') });
        break;
      }

    }

  };

  const smoothUpdateProgress = (targetProgress: number) => {
    const step = 1;
    const updatedState = store.getState()
    const currentProgress = updatedState.convertingProgress
    const interval = 2000 / (targetProgress - currentProgress);
    const incrementProgress = () => {
      const updatedState = store.getState()
      let currentProgress = updatedState.convertingProgress
      if (currentProgress !== -1 && currentProgress !== -2 && currentProgress < targetProgress) {
        currentProgress += step;
        if (currentProgress > targetProgress) {
          currentProgress = targetProgress;
        }
        dispatch({
          type: CONSTANTS.SET_CONVERTING_PROGRESS,
          payload: currentProgress,
        });
        setTimeout(incrementProgress, Math.max(interval, 0));
      }
    };

    incrementProgress();
  };


  const audioScriptGeneration = async (file: File) => {
    dispatch({ type: CONSTANTS.SET_IS_CONVERT_DISABLED, payload: false });
    dispatch({ type: CONSTANTS.SET_CONVERTING_PROGRESS, payload: 0 });
    dispatch({ type: CONSTANTS.SET_LLM_RUN, payload: t('smartslide.running_state') });
    const updatedState = store.getState();
    const pdfData: any = {
      pptx_id: updatedState.id,
    };
    updatedState.pdfFiles.forEach((file: File, index: number) => {
      const fileKey = `file${index + 1}`;
      pdfData[fileKey] = file;
    });
    try {
      const headers = baseApiParams()
      headers['headers']['server'] = process.env.REACT_APP_MEDIA_SERVER
      const dbBuildResult = (await (await apiPptx2video.pptx2Video.buildRagCreate(pdfData, headers)).json())['message']
      if (dbBuildResult !== 'success') {
        console.log('pdf upload failed')
      }
    } catch (error) {

    }
    for (let slide = 1; slide <= slideCount; slide++) {
      const updatedState = store.getState();
      if (updatedState.isConvertDisabled) {
        dispatch({ type: CONSTANTS.SET_LLM_RUN, payload: t('smartslide.interrupted_state') });
        cleanPptxMeta()
        break
      }
      const resol = parseResolution(resolution);

      const data = {
        "pptx_id": id,
        "slide_index": slide,
        "slide_sum": slideCount,
        "chatgpt_model": 'gpt-4o',
        "language": language,
        "topic": specification,
        "width": resol.w,
        "height": resol.h,
        "useNotes": notesIncluded
      }
      const headers = baseApiParams()
      headers['headers']['server'] = process.env.REACT_APP_MEDIA_SERVER
      const thread_id = (await (await apiPptx2video.pptx2Video.slideAudioScriptCreate(data, headers)).json())['thread_id']

      let isCompleted = false;
      while (!isCompleted) {
        const headers = baseApiParams()
        headers['headers']['server'] = process.env.REACT_APP_MEDIA_SERVER
        const state = (await (await apiPptx2video.pptx2Video.statusDetail(thread_id, headers)).json())['message'];

        if (state === 'Running') {
          console.log('running');
        } else if (state === 'Completed') {
          const headers = baseApiParams()
          headers['headers']['server'] = process.env.REACT_APP_MEDIA_SERVER
          const result = (await (await apiPptx2video.pptx2Video.resultDetail(thread_id, headers)).json())['message'];
          sibling_scripts[String(currentSlide)] = result
          dispatch({ type: CONSTANTS.SET_SIBLING_SCRIPTS, payload: { ...sibling_scripts } });
          console.log(sibling_scripts)
          currentSlide += 1;
          isCompleted = true;
        } else {
          console.log(`error: ${state}`);
          isCompleted = true;
        }

        if (!isCompleted) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }

      smoothUpdateProgress(Math.floor((slide / slideCount) * 100))

      if (slide === slideCount) {
        dispatch({ type: CONSTANTS.SET_CONVERTING_PROGRESS, payload: 100 });
        dispatch({ type: CONSTANTS.SET_LLM_RUN, payload: t('smartslide.success_state') });
        break;
      }

    }
  };

  const interrupthandling = () => {
    dispatch({ type: CONSTANTS.SET_IS_CONVERT_DISABLED, payload: true });
    closeUploadedPptx();
  }

  const closeUploadedPptx = () => {
    cleanPptxMeta()
    if (cancelRef.current) {
      cancelRef.current.click()
    }
  }

  const resetAll = () => {
    interrupthandling();
    closeUploadedPptx();
    dispatch({ type: CONSTANTS.RESET_ALL });
  }

  const renderUploadButton = () => (
    <Button className={(convertingProgress === 100 || llmRun === t('smartslide.running_state')) ? 'upload-pptx-button-done' : 'upload-pptx-button'} onClick={handlePptxUploadButtonClick}>
      <Text className={(convertingProgress === 100 || llmRun === t('smartslide.running_state')) ? 'upload-pptx-button-text-done' : 'upload-pptx-button-text'}>
        {t('smartslide.presentation_upload')}
      </Text>
      <FiUpload className={(convertingProgress === 100 || llmRun === t('smartslide.running_state')) ? 'upload-pptx-button-icon-done' : 'upload-pptx-button-icon'} />
      <Input
        ref={inputPptxRef}
        type="file"
        accept=".pptx"
        onChange={handlePptxUpload}
        style={{ display: 'none' }}
      />
    </Button>
  );

  return (

    <Flex className='content-right-side'>
      <Flex className={(convertingProgress === 100 || llmRun === t('smartslide.running_state')) ? 'upload-pptx-done' : 'upload-pptx'}
        style={{
          background: thumbnailUrlRef.current && thumbnailUrl
            ? `linear-gradient(0deg, rgba(44, 122, 123, 0.20) 0%, rgba(44, 122, 123, 0.20) 100%), url(${thumbnailUrlRef.current}) lightgray 50% / cover no-repeat`
            : "#2C7A7B",
        }}>
        {((convertingProgress === -1 || convertingProgress === 100) || llmRun === t('smartslide.running_state')) ? (
          <>
            { // show a close button when progress is 100
              (!(convertingProgress !== 100 || llmRun === t('smartslide.running_state'))) &&
              <>
                <IoClose className='upload-pptx-button-done-close' onClick={onOpen} />
                <CustomAlertDialog
                  leastDestructiveRef={cancelRef}
                  onClose={onClose}
                  isOpen={isOpen}
                  topbuttonfunction={onClose}
                  bottombuttonfunction={closeUploadedPptx}
                  content={[t('smartslide.are_you_sure_to_delete'), t('smartslide.preferences_will_not_be_lost'), t('smartslide.cancle_delete'), t('smartslide.delete')]}
                  level='warning' />
              </>
            }
            <Flex className={convertingProgress === 100 ? 'upload-pptx-button-done-shell' : ''}>
              {renderUploadButton()}
            </Flex>
          </>
        ) : (

          <Flex className='progress-with-close'>
            {(convertingProgress === -2) ?
              (<Text className='uploading'>{t('smartslide.uploading')}...</Text>) :
              (
              <><Progress className='progress' value={convertingProgress}></Progress><Flex className='progress-close-button' onClick={interrupthandling}>
                <IoClose className='progress-close-button-icon' />
              </Flex></>
              )
            }
          </Flex>
        )}
      </Flex>
      <Flex className='info-pptx'>
        {(pptxFile) ? (
          <>
            {(fileName !== '') && (
              <Text className='upload-pptx-info-text-head'>
                {t('smartslide.title')}:
                <span className='upload-pptx-info-text-content'> {fileName}</span>
              </Text>
            )}

            {(slideCount !== -1) && (
              <Text className='upload-pptx-info-text-head'>
                {t('smartslide.slides_number')}:
                <span className='upload-pptx-info-text-content'> {slideCount}</span>
              </Text>
            )}

            {(convert === t('smartslide.success_state')) && (
              <>
                <Text className='upload-pptx-info-text-head'>
                  {t('smartslide.storage')}:
                  <span className='upload-pptx-info-text-content'> {t('smartslide.smartslide_videos')}</span>
                </Text>
                <Box className='upload-pptx-info-text-head'>
                  {t('smartslide.converting_of_animations')}:
                  <span className='upload-pptx-info-text-content'> {convert}</span>
                </Box>
              </>
            )}
            {!(fileName !== '' &&
              slideCount !== -1 &&
              convert !== t('smartslide.running_state')) && (
                (convertingProgress === -2)?
                (<Text className='upload-pptx-info-text-content-uploading'>...</Text>):
                (<Text className='upload-pptx-info-text-content-uploading'>{t('smartslide.presentation_is_being_analyzed')} ...</Text>)
              )}
          </>
        ) : null
        }
      </Flex>
      <Flex className='hinweis-pptx'>
        <Text className='hinweis-text'>{t('smartslide.presentation_upload_hint')}</Text>
      </Flex>
    </Flex>

  );
});
export function estimateSentenceDuration(sentence: string, timePerWord: number = 0.6): number {
    sentence = sentence.replace('[MEDIA WAIT]', ' ')
    const wordCount = sentence.trim().split(/\s+/).length;

    const totalSeconds = wordCount * timePerWord;

    return totalSeconds
}

export function normalDurationFormat(totalSeconds:number): string {
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds % 3600) / 60);
    let seconds = Math.round(totalSeconds % 60);

    if (seconds === 60) {
        minutes += 1;
        seconds = 0;
    }
    if (minutes === 60) {
        hours += 1;
        minutes = 0;
    }

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
import { Api as Pptx2VideoApi } from '../models/myApi-pptx2video';
import { Api as MediaApi } from '../models/myApi-media'
import { Api as MedimeshApi } from 'my-api-client-package'

export const apiPptx2video = new Pptx2VideoApi({
    securityWorker: async (securityData) => {
        const requestParams = {
            headers: {
                "x-api-key": securityData as string,
            },
        };
        return requestParams;
    },
    baseUrl: process.env.REACT_APP_PPTX2VIDEO_API_URL,
});

export const apiMedia = new MediaApi({
    securityWorker: async (securityData) => {
        const requestParams = {
            headers: {
                "x-api-key": securityData as string,
            },
        };
        return requestParams;
    },
    baseUrl: process.env.REACT_APP_MEDIA_API_URL,
});

export const apiMedimesh = new MedimeshApi()
// const apiKey = process.env.REACT_APP_API_KEY
// apiPptx2video.setSecurityData(apiKey)
// apiMedia.setSecurityData(apiKey)
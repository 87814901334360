import { CONSTANTS_RATE_MY } from '../constants.js';
import { baseApiParams, Api, handleApiError } from 'my-api-client-package';

export const loadUserInfo = async (token: string, dispatch: any, navigate: any) => {
    const api = new Api();

    if (token) {
        try {
            const loggedInUser = await api.user.getCurrentUser(baseApiParams());
            const userData = loggedInUser.data;
            dispatch({ type: CONSTANTS_RATE_MY.CHANGE_USER, payload: userData });

            navigate("/smartslide");

        } catch (err) {
            handleApiError(err, navigate);
        }
    }
};

import { useRef } from 'react';
import './Pptx2video.scss';
import { useTranslation } from "react-i18next";
import {
  Button,
  Flex,
  Text,
  Box,
  Progress,
} from '@chakra-ui/react';
import { LeftSettingSide } from './LeftSettingSide';
import { RightSettingSide } from './RightSettingSide';
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from '../../constants';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import store from '../../store';
import { requestRefreshToken, getAuthAccessObject } from 'my-api-client-package';
import { useEffect } from 'react';

export const Pptx2video = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const llmRun = useSelector((state: any) => state.llmRun);

  useEffect(() => {
    if (getAuthAccessObject().token) {
      dispatch({ type: CONSTANTS.CONSTANTS_RATE_MY.CHANGE_TOKEN, payload: getAuthAccessObject().token })
    }
    const intervalId = setInterval(() => {
      let refreshtoken = requestRefreshToken(navigate);
      refreshtoken.then((resp: any) => dispatch({ type: CONSTANTS.CONSTANTS_RATE_MY.CHANGE_TOKEN, payload: resp.data.message }));
    }, 600000); // Every 10 minutes

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const resetAll = async () => {
    await childRef.current?.resetAll();
  }

  const childRef = useRef<{
    resetAll: () => void;
    audioScriptGeneration: () => Promise<void>;
    interrupthandling: () => void;
  }>(null);

  const sibling_scripts = useSelector((state: any) => state.sibling_scripts);
  const current_slide = useSelector((state: any) => state.current_slide);

  const convertingProgress = useSelector((state: any) => state.convertingProgress);

  const initScripts = async () => {
    return new Promise((resolve, reject) => {
      childRef.current?.audioScriptGeneration().then((done: any) => {
        if (done === 'break') {
          reject(false);
        }
        const updatedState = store.getState();
        if (updatedState.convertingProgress === 100) {
          dispatch({ type: CONSTANTS.SET_CURRENT_SLIDE, payload: [0, 0] });
          dispatch({ type: CONSTANTS.SET_MEMORY_SCRIPTS, payload: cloneDeep(sibling_scripts) })
          dispatch({ type: CONSTANTS.SET_EDITABLE_SCRIPTS, payload: cloneDeep(sibling_scripts) })

          const edit_history: { [key: number]: any } = {}
          Object.keys(sibling_scripts).forEach((key: any, index: number) => {
            edit_history[key] = {}
            Object.keys(sibling_scripts[key]['siblings']).forEach((sibling_key: string, index: number) => {
              if (sibling_key !== 'thumbnail') {
                const sibling_index = Number(sibling_key.split(' ')[1])
                edit_history[key][sibling_index] = { "history": [], "used": 0 }
                edit_history[key][sibling_index]['history'] = [sibling_scripts[key]['siblings'][sibling_key]['text']]
                edit_history[key][sibling_index]['used'] = 0
              }
            })
          })

          dispatch({ type: CONSTANTS.SET_EDIT_HISTORY, payload: cloneDeep(edit_history) })
          resolve(true)
        } else {
          reject(false);
        }
      }).catch(error => {
        reject(false);
      });
    })
  }

  const toScript = () => {
    if (current_slide[0] === -1) {
      initScripts()
        .then((isDone) => {
          if (isDone) {
            const updatedState = store.getState();
            if (updatedState.convertingProgress === 100 && !updatedState.isConvertDisabled) {
              navigate('/smartslide/audioScript');
            } else {
              resetAll()
            }
          }

        })
        .catch((error) => {
        });
    } else {
      const updatedState = store.getState();
      if (updatedState.convertingProgress === 100 && !updatedState.isConvertDisabled) {
        navigate('/smartslide/audioScript');
      } else {
        resetAll()
      }
    }
  };

  return (
    <Box className='pptx2video'>
      <Flex className='pptx2video-setting-page'>
        <Flex className='content-group'>
          <LeftSettingSide resetAll={resetAll} />
          <RightSettingSide ref={childRef} />
        </Flex>

        <Flex className="footer">
          {((llmRun === t('smartslide.running_state'))) && (
            <>
              <Box className='upload-pptx-info-text-content-audio-generating'>
                {t('smartslide.audio_script_is_being_created')}
              </Box>
              <Flex className='progress-with-close'>
                <Progress className='progress' value={convertingProgress}></Progress>
              </Flex>
            </>
          )}
          <Button
            className={llmRun !== t('smartslide.running_state') ? "footer-button" : "footer-button-cancel"}
            onClick={(llmRun === t('smartslide.running_state'))?(childRef.current?.interrupthandling):(toScript)}
            isDisabled={convertingProgress !== 100 && llmRun !== t('smartslide.running_state')}>
            {
              Object.keys(sibling_scripts).length && current_slide[0] !== -1 ?
                (
                  <Text className='footer-button-text'>{t('smartslide.continue')}</Text>
                )
                :
                (
                  (llmRun === t('smartslide.running_state'))?
                  (<Text className='cancel-text'>{t('general.cancel')}</Text>):
                  (<Text className='footer-button-text'>{t('smartslide.make_audio_script')}</Text>)
                )
            }
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
import { useEffect, useState } from 'react';
import './AudioScript.scss';
import {
  Flex
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { DownloadMedia } from '../../helpers/download'

export const SlideFliedOfView = () => {
  const sibling_scripts = useSelector((state: any) => state.sibling_scripts);
  const current_slide = useSelector((state: any) => state.current_slide);

  const [thumbnail, setThumbnail] = useState('');

  const getPicture = async (pictureId: string): Promise<string> => {
    const pictureUrl = await DownloadMedia(pictureId);
    return pictureUrl
  }

  useEffect(() => {
    const fetchThumbnail = async () => {
      const thumbnail = await getPicture(sibling_scripts[current_slide[0]].siblings[`SIBLING ${current_slide[1]}`]['picture']);
      setThumbnail(thumbnail);
    };

    fetchThumbnail();
  }, [current_slide, sibling_scripts]);

  return (
    <Flex className='page2-left-side'>
      <Flex className='page2-left-side-image' style={{ backgroundImage: `url(${thumbnail})` }}></Flex>
    </Flex>
  );
};
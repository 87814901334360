import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './AudioScript.scss';
import { Trans, useTranslation } from "react-i18next";
import {
  Flex,
  Text,
  Box,
  Tooltip,
  Tag,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from '../../constants';
import { IoReturnUpBackOutline, IoReturnUpForward } from "react-icons/io5";
import { BiTime } from 'react-icons/bi';
import React from 'react';
import { cloneDeep } from 'lodash';
import { estimateSentenceDuration, normalDurationFormat } from '../../helpers/duration_caculator';
import { createEditor, Editor, Transforms } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import store from '../../store';
import { CustomAlertDialog } from '../CustomAlert/CustomAlert'
import { DownloadMedia } from '../../helpers/download'
import { FiFilm, FiMusic } from "react-icons/fi";

export const ScriptZone = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sibling_scripts = useSelector((state: any) => state.sibling_scripts);
  const current_slide = useSelector((state: any) => state.current_slide);
  const editable_scripts = useSelector((state: any) => state.editable_scripts);
  const memory_scripts = useSelector((state: any) => state.memory_scripts);
  const edit_history = useSelector((state: any) => state.edit_history);

  const [beforeScripts, setBeforeScripts] = useState([]);
  const [afterScripts, setAfterScripts] = useState([]);
  const editor = useMemo(() => withReact(createEditor()), [])

  useEffect(() => {
    showUpdatedEditableScripts();
  }, [current_slide]);

  const showUpdatedEditableScripts = () => {
    setBeforeScripts(getBeforeScripts())
    setAfterScripts(getAfterScripts())
    const currentSelection: any = { ...editor.selection };
    const newChildren = handelShow(editable_scripts[current_slide[0]]['siblings'][`SIBLING ${current_slide[1]}`]['text'])
    if (editor.children.length > 0) {
      Transforms.select(editor, { anchor: { path: [0, 0], offset: 0 }, focus: { path: [0, 0], offset: 0 } });
    }
    const lengthSame = editor.children.length === newChildren.length
    while (editor.children.length > 0) {
      Transforms.removeNodes(editor, { at: [0] });
    }
    Transforms.insertNodes(editor, newChildren);
    if (currentSelection && lengthSame) {
      Transforms.select(editor, currentSelection);
    } else {
      if (editor.children.length > 0) {
        Transforms.select(editor, { anchor: { path: [0, 0], offset: 0 }, focus: { path: [0, 0], offset: 0 } });
      }
    }
  }

  useEffect(() => {
    if (editor.children.length > 0) {
      Transforms.select(editor, { anchor: { path: [0, 0], offset: 0 }, focus: { path: [0, 0], offset: 0 } });
    }
  }, [current_slide, sibling_scripts]);

  useEffect(() => {
    const updatedScripts = { ...editable_scripts };
    Object.keys(edit_history).forEach((key: any, index: number) => {
      Object.keys(edit_history[key]).forEach((sib_key: string, index: number) => {
        const which_used = edit_history[key][sib_key]['used']
        updatedScripts[key]['siblings'][`SIBLING ${sib_key}`]['text'] = edit_history[key][sib_key]['history'][which_used]
      })
    })
    dispatch({ type: CONSTANTS.SET_EDITABLE_SCRIPTS, payload: updatedScripts });
    showUpdatedEditableScripts()
  }, [edit_history]);

  const clickMe = (slide_sibling_index: any): any => {
    dispatch({ type: CONSTANTS.SET_CURRENT_SLIDE, payload: slide_sibling_index });
  }

  // for reset slide script
  const { isOpen: isSlideResetOpen, onOpen: onSlideResetOpen, onClose: onSlideResetCLose } = useDisclosure()
  const slideResetRef: MutableRefObject<any> = React.useRef()

  const getBeforeScripts = (): any => {
    const _beforeScripts = []
    for (let slide_i = 0; slide_i < current_slide[0]; slide_i++) {
      const sibling_sum = Object.keys(editable_scripts[slide_i]['siblings']).length - 1
      for (let sibling_index = 0; sibling_index < sibling_sum; sibling_index++) {
        _beforeScripts.push([editable_scripts[slide_i]['siblings'][`SIBLING ${sibling_index}`]['text'], [slide_i, sibling_index]])
      }
    }
    for (let sibling_i = 0; sibling_i < current_slide[1]; sibling_i++) {
      _beforeScripts.push([editable_scripts[current_slide[0]]['siblings'][`SIBLING ${sibling_i}`]['text'], [current_slide[0], sibling_i]])
    }
    return _beforeScripts
  }

  const getAfterScripts = (): any => {
    const _afterScripts = []
    const n = Object.keys(editable_scripts[current_slide[0]]['siblings']).length - 1
    for (let sibling_i = current_slide[1] + 1; sibling_i < n; sibling_i++) {
      _afterScripts.push([editable_scripts[current_slide[0]]['siblings'][`SIBLING ${sibling_i}`]['text'], [current_slide[0], sibling_i]])
    }
    const m = Object.keys(editable_scripts).length
    for (let slide_i = current_slide[0] + 1; slide_i < m; slide_i++) {
      const sibling_sum = Object.keys(editable_scripts[slide_i]['siblings']).length - 1
      for (let sibling_index = 0; sibling_index < sibling_sum; sibling_index++) {
        _afterScripts.push([editable_scripts[slide_i]['siblings'][`SIBLING ${sibling_index}`]['text'], [slide_i, sibling_index]])
      }
    }
    return _afterScripts
  }

  const resetThisSlideScript = (): any => {
    if (editor.children.length > 0) {
      Transforms.select(editor, { anchor: { path: [0, 0], offset: 0 }, focus: { path: [0, 0], offset: 0 } });
    }
    const updateEditHistory = { ...edit_history }
    const sibling_key = `SIBLING ${current_slide[1]}`
    updateEditHistory[current_slide[0]][current_slide[1]] = {
      "history": [memory_scripts[current_slide[0]]['siblings'][sibling_key]['text']],
      "used": 0
    }
    // console.log(updateEditHistory)
    // Object.keys(memory_scripts[current_slide[0]]['siblings']).map((sibling_key: string, index) => {
    //     if (sibling_key != 'thumbnail') {
    //       const sibling_index = Number(sibling_key.split(' ')[1])
    //       updateEditHistory[current_slide[0]][sibling_index] = { "history": [], "used": 0 }
    //       updateEditHistory[current_slide[0]][sibling_index]['history'] = [memory_scripts[current_slide[0]]['siblings'][sibling_key]['text']]
    //       updateEditHistory[current_slide[0]][sibling_index]['used'] = 0
    //     }
    // })

    dispatch({ type: CONSTANTS.SET_EDIT_HISTORY, payload: cloneDeep(updateEditHistory) })
    slideResetRef.current.click()
  }

  const handleTextChange = (input: any) => {
    let newText = ''
    input.forEach((childrenItem: any, index: number) => {
      childrenItem['children'].forEach((textObj: any, index: number) => {
        newText += textObj['text']
      })
    })

    const updateEditHistory = { ...edit_history }
    if (updateEditHistory[current_slide[0]][current_slide[1]]['history'].slice(-1)[0] !== newText) {
      updateEditHistory[current_slide[0]][current_slide[1]]['history'].push(newText)
      updateEditHistory[current_slide[0]][current_slide[1]]['used'] = updateEditHistory[current_slide[0]][current_slide[1]]['history'].length - 1
      dispatch({ type: CONSTANTS.SET_EDIT_HISTORY, payload: updateEditHistory });
    }
  };

  const toLastVersion = () => {
    const current_used_index = edit_history[current_slide[0]][current_slide[1]]['used']
    if (current_used_index !== 0) {
      const updateEditHistory = { ...edit_history }
      updateEditHistory[current_slide[0]][current_slide[1]]['used'] = current_used_index - 1
      dispatch({ type: CONSTANTS.SET_EDIT_HISTORY, payload: updateEditHistory });
    }
  }

  const toNextVersion = () => {
    const current_used_index = edit_history[current_slide[0]][current_slide[1]]['used']
    if (current_used_index !== edit_history[current_slide[0]][current_slide[1]]['history'].length - 1) {
      const updateEditHistory = { ...edit_history }
      updateEditHistory[current_slide[0]][current_slide[1]]['used'] = current_used_index + 1
      dispatch({ type: CONSTANTS.SET_EDIT_HISTORY, payload: updateEditHistory });
    }
  }

  const scrollPriviousRef = useRef(null);
  useEffect(() => {
    const element: any = scrollPriviousRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [beforeScripts]);

  const scrollAllRef = useRef(null);
  useEffect(() => {
    const element: any = scrollAllRef.current;
    if (element) {
      element.scrollTop = 0;
    }
  }, [current_slide]);

  const getDuration = (sibling: any): string => {
    const text_duration = estimateSentenceDuration(sibling['text'])
    let media_duration = 0
    sibling['media_elements'].forEach((media_item: any) => {
      media_duration += media_item['obj']['trim_duration']
    })
    return normalDurationFormat(text_duration + media_duration)
  }

  const clickOnMedia = async (mediaId: string) => {
    const mediaUrl = await DownloadMedia(mediaId);
    window.open(mediaUrl)
  }

  const Element = ({ attributes, children, element }: any) => {
    switch (element.type) {
      case 'tag':
        try {
          const updatedState = store.getState();
          const mediaObj = updatedState.editable_scripts[updatedState.current_slide[0]]['siblings'][`SIBLING ${updatedState.current_slide[1]}`]['media_elements'][element.index]['obj']
          return (
            <span {...attributes} contentEditable={false} className='media-tag-shell'>
              <Tooltip className='tooltip' label={mediaObj.object_type === 'Video'?t('smartslide.video_hint'):t('smartslide.audio_hint')}>
                <Tag {...attributes} borderRadius="full" variant="solid" colorScheme="teal" className='media-tag' onClick={() => { clickOnMedia(mediaObj.media_id) }}>
                  {
                    mediaObj.object_type === 'Video' ?
                      (<FiFilm className='media-tag-icon' />)
                      :
                      (<FiMusic className='media-tag-icon' />)
                  }
                  <Text className='media-tag-text'>{`${mediaObj.object_type}: ${Math.round(mediaObj.trim_duration)}s`}</Text>
                </Tag>
              </Tooltip>
            </span>
          );
        } catch (error) {
          return (
            <span {...attributes} contentEditable={false}></span>
          )
        }

      case 'paragraph': {
        const isEmpty = (children[0].props && children[0].props.text.text === '');
        return (
          <p {...attributes} style={{ position: 'relative' }}>
            {isEmpty && (
              <span
                style={{
                  position: 'absolute',
                  color: 'gray',
                  pointerEvents: 'none',
                }}
              >
                {t('smartslide.add_audio_script')}
              </span>
            )}
            {children}
          </p>
        );
      }

      default:
        return <p {...attributes}>{children}</p>;
    }
  };
  const renderElement = useCallback((props: any) => <Element {...props} />, []);

  const handelShow = (text: any): any => {
    const updatedState = store.getState();
    if (!text.includes('[MEDIA WAIT]') || updatedState.editable_scripts[updatedState.current_slide[0]]['siblings'][`SIBLING ${updatedState.current_slide[1]}`]['media_elements'].length === 0) {
      return [{
        type: 'paragraph',
        children: [
          { text: text }
        ]
      }]
    } else {
      if (text === '[MEDIA WAIT]') {
        return [
          {
            type: 'paragraph',
            children: [
              { text: '' }
            ]
          },
          {
            type: 'tag',
            index: 0,
            children: [
              { text: '[MEDIA WAIT]' }
            ]
          },
          {
            type: 'paragraph',
            children: [
              { text: '' }
            ]
          }
        ]
      } else {
        const childrens: any = []
        const updatedState = store.getState();
        const mediaCount = updatedState.editable_scripts[updatedState.current_slide[0]]['siblings'][`SIBLING ${updatedState.current_slide[1]}`]['media_elements'].length

        const modifiedText = text.replace(/\[MEDIA WAIT\]/g, (match: any, offset: any, str: string) => {
          const count = (str.slice(0, offset).match(/\[MEDIA WAIT\]/g) || []).length;
          return count < mediaCount ? match : `[media wait]`;
          // If the media is an illusion of llm, it is not added as a tag. The reason for converting to lowercase is to avoid the cursor selecting the wrong position.
        });
        const parts = modifiedText
          .split(/(\[MEDIA WAIT\])/) // Split by "[MEDIA WAIT]" while keeping it as a separate token
        let media_index = 0
        parts.forEach((part: string, index: number) => {
          if (part !== '[MEDIA WAIT]') {
            childrens.push({
              type: 'paragraph',
              children: [
                { text: part }
              ]
            })
          } else {
            if (index === 0) {
              childrens.push({
                type: 'paragraph',
                children: [
                  { text: '' }
                ]
              })
              childrens.push({
                type: 'tag',
                index: media_index,
                children: [
                  { text: '[MEDIA WAIT]' }
                ]
              })
            }
            else if (index === parts.length - 1) {
              childrens.push({
                type: 'tag',
                index: media_index,
                children: [
                  { text: '[MEDIA WAIT]' }
                ]
              })
              childrens.push({
                type: 'paragraph',
                children: [
                  { text: '' }
                ]
              })
            }
            else {
              childrens.push({
                type: 'tag',
                index: media_index,
                children: [
                  { text: '[MEDIA WAIT]' }
                ]
              })
            }
            media_index += 1
          }
        });
        return childrens
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const { selection } = editor;
    if (event.key === 'Enter') {
      event.preventDefault();
      Transforms.insertText(editor, '\n');
    }
    if (event.key === 'Backspace') {
      if (selection && Editor.isStart(editor, selection.anchor, selection.focus.path)) {
        event.preventDefault();
        // console.log("Cannot delete at the start of the line.");
      }
    }
    if (event.key === 'ArrowLeft') {
      if (selection && Editor.isStart(editor, selection.anchor, selection.focus.path)) {
        event.preventDefault();
        // console.log("Cannot move left at the start of the line.");
      }
    }
    if (event.key === 'ArrowRight') {
      if (selection && Editor.isEnd(editor, selection.anchor, selection.focus.path)) {
        event.preventDefault();
        // console.log("Cannot move right at the end of the line.");
      }
    }
    if (event.key === 'Delete') {
      if (selection && Editor.isEnd(editor, selection.anchor, selection.focus.path)) {
        event.preventDefault();
        // console.log("Cannot delete at the end of the line.");
      }
    }
  };

  return (
    <Flex className='page2-right-side-with-scrollbar' ref={scrollAllRef}>
      <Flex className='page2-right-side-with-scrollbar-content'>
        <Flex className='page2-header-right-side'>
          <Flex className='page2-pos-folie-zurucksetzten'>
            <Flex className='page2-pos-folie-zurucksetzten-button'>
              <Text className='page2-pos-folie-zurucksetzten-button-text'>{t('smartslide.previous_slide')}</Text>
            </Flex>
          </Flex>
          <Box className='page2-before' ref={scrollPriviousRef}>
            {beforeScripts.map((oldScript, index) => (
              <Tooltip key={index} className='tooltip' closeOnClick={false} label={t('smartslide.previous_slide') + `: ${oldScript[1][0] + 1}.${oldScript[1][1] + 1}`}>
                <Flex className='page2-old-folie' onClick={() => clickMe(oldScript[1])}>
                  <Flex className='page2-old-folie-text-zone'>
                    <Flex className='page2-old-folie-text'>
                      {oldScript[0]}
                    </Flex>
                  </Flex>
                </Flex>
              </Tooltip>

            ))}
          </Box>

        </Flex>

        <Flex className='page2-header-aktuelle-folie'>
          <Flex className='page2-pos-folie-zuruck'>
            <Flex className='page2-pos-folie-zuruck-button' onClick={onSlideResetOpen}>
              <Text className='page2-pos-folie-zuruck-button-text'>
                {t('smartslide.reset_slide')}
              </Text>
            </Flex>
            <CustomAlertDialog
              leastDestructiveRef={slideResetRef}
              onClose={onSlideResetCLose}
              isOpen={isSlideResetOpen}
              topbuttonfunction={onSlideResetCLose}
              bottombuttonfunction={resetThisSlideScript}
              content={[<Trans i18nKey="smartslide.reset_slide_title" components={{ current: <span style={{ color: 'var(--teal-600, #2C7A7B)' }} /> }} />, t('smartslide.reset_slide_warning'), t('smartslide.cancel_reset_slide'), t('smartslide.confirm_reset_slide')]}
              level='warning'
            />
          </Flex>
          <Flex className='page2-zuruck-arrows'>
            <Flex className='page2-zuruck-arrows-buttongroup'>
              <Tooltip className='tooltip' closeOnClick={false} label={t('smartslide.undo_typing')}>
                <Flex
                  className={
                    (edit_history[current_slide[0]][current_slide[1]]['used'] === 0)
                      ? 'page2-zuruck-arrows-buttongroup-button-disabled' : 'page2-zuruck-arrows-buttongroup-button'
                  }
                  style={{ borderRight: '1px solid #E2E8F0' }}
                  onClick={toLastVersion}
                >
                  <IoReturnUpBackOutline className='page2-zuruck-arrows-buttongroup-button-icon' />
                </Flex>
              </Tooltip>
              <Tooltip className='tooltip' closeOnClick={false} label={t('smartslide.redo_typing')}>
                <Flex
                  className={
                    (edit_history[current_slide[0]][current_slide[1]]['used'] === edit_history[current_slide[0]][current_slide[1]]['history'].length - 1)
                      ? 'page2-zuruck-arrows-buttongroup-button-disabled' : 'page2-zuruck-arrows-buttongroup-button'
                  }
                  onClick={toNextVersion}
                >
                  <IoReturnUpForward className='page2-zuruck-arrows-buttongroup-button-icon' />
                </Flex>
              </Tooltip>
            </Flex>
          </Flex>
        </Flex>

        <Flex className='page2-selected-slide'>
          <Flex className='page2-selected-slide-text'>
            <Slate
              editor={editor}
              initialValue={[{ type: 'paragraph', children: [{ text: 'default' }] }]}
              onValueChange={handleTextChange}
            >
              <Editable className='page2-selected-slide-text-content' renderElement={renderElement} onKeyDown={handleKeyDown} />
            </Slate>
            <Flex className='page2-selected-slide-text-time-pos'>
              <Flex className='page2-selected-slide-text-time-shell'>
                <Flex className='page2-selected-slide-text-time-icon-pos'>
                  <BiTime className='page2-selected-slide-text-time-icon-pos' />
                </Flex>
                <Box className='page2-selected-slide-text-time-content'>{getDuration(editable_scripts[current_slide[0]]['siblings'][`SIBLING ${current_slide[1]}`])}</Box  >
              </Flex>
              {
                editable_scripts[current_slide[0]]['siblings'][`SIBLING ${current_slide[1]}`]['text'] !== memory_scripts[current_slide[0]]['siblings'][`SIBLING ${current_slide[1]}`]['text']
                  ? (<Flex className='page2-selected-slide-text-time-pos-badge'>
                    <Flex className='page2-selected-slide-text-time-pos-badge-badge'>
                      <Flex className='page2-selected-slide-text-time-pos-badge-badge-text'>{t('smartslide.edited')}</Flex>
                    </Flex>
                  </Flex>)
                  : (<></>)
              }
            </Flex>
          </Flex>
        </Flex>

        <Flex className='page2-header-right-side'>
          {afterScripts.map((oldScript, index) => (
            <Tooltip key={index} className='tooltip' closeOnClick={false} label={t('smartslide.next_slide') + `: ${oldScript[1][0] + 1}.${oldScript[1][1] + 1}`}>
              <Flex className='page2-old-folie' onClick={() => clickMe(oldScript[1])}>
                <Flex className='page2-old-folie-text-zone'>
                  <Flex className='page2-old-folie-text'>
                    {oldScript[0]}
                  </Flex>
                </Flex>
              </Flex>
            </Tooltip>
          ))}
        </Flex>
      </Flex>
    </Flex >
  );
};
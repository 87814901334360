import store from '../store';

export const toFrontendApp = async (worklfowId:string) => {
    try {
        const updatedState = store.getState();
        const token = updatedState.token
        const email = updatedState.user.email
        const redirectUrl = `${process.env.REACT_APP_FRONTEND_URL}/dashboard?_workflow_id=${worklfowId}&_token=${token}&_email=${email}&_redirectBy=smart_slide`;
        console.log(redirectUrl)
        // window.open(redirectUrl, "_blank");
        return redirectUrl
    } catch (e) {
        console.error("Error:", e);
        return ''
    }
};


export const getLibraryId = async () => {
    return '673f2102c00e1d699a10ceed'
}
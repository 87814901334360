import { MdOutlineDirectionsWalk, MdOutlineDirectionsRun, MdOutlineElderly } from "react-icons/md";

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_GENDER = 'SET_GENDER';
export const SET_VOICE = 'SET_VOICE';
export const SET_SPEED = 'SET_SPEED';
export const SET_RESOLUTION = 'SET_RESOLUTION';
export const SET_SPECIFICATION = 'SET_SPECIFICATION';
export const SET_PPTX_FILE = 'SET_PPTX_FILE';
export const SET_PDF_FILES = 'SET_PDF_FILES';
export const SET_NOTES_INCLUDED = 'SET_NOTES_INCLUDED';
export const SET_CONVERTING_PROGRESS = 'SET_CONVERTING_PROGRESS';
export const SET_IS_CONVERT_DISABLED = 'SET_IS_CONVERT_DISABLED';
export const SET_FILE_NAME = 'SET_FILE_NAME';
export const SET_SLIDE_COUNT = 'SET_SLIDE_COUNT';
export const SET_CONVERT = 'SET_CONVERT';
export const SET_ID = 'SET_ID';
export const SET_THUMBNAIL_ID = 'SET_THUMBNAIL_ID';
export const SET_THUMBNAIL_URL = 'SET_THUMBNAIL_URL';
export const RESET_ALL = 'RESET_ALL';
export const SET_SIBLING_SCRIPTS = 'SET_SIBLING_SCRIPTS'
export const SET_CURRENT_SLIDE = 'SET_CURRENT_SLIDE'
export const SET_EDITABLE_SCRIPTS = 'SET_EDITABLE_SCRIPTS'
export const SET_MEMORY_SCRIPTS = 'SET_MEMORY_SCRIPTS'
export const SET_EDIT_HISTORY = 'SET_EDIT_HISTORY'
export const SET_LLM_RUN = 'SET_LLM_RUN'

export const LANGUAGE_SELECTIONS = ['', 'German', 'English']
export const VOICE_SELECTIONS = ['female', 'male']
export const SPEED_SELECTIONS = {
    'slow': <MdOutlineElderly className='pos-speed-button-icon' />,
    'normal': <MdOutlineDirectionsWalk className='pos-speed-button-icon' />,
    'fast': <MdOutlineDirectionsRun className='pos-speed-button-icon' />
}
export const RESOLUTION_SELECTIONS = ['720p', '1080p']

export const CONSTANTS_RATE_MY = { // from rate my
    PASSWORD: 'PASSWORD',

    USERNAME: 'USERNAME',
    UPDATE_CV: 'UPDATE_CV',
    UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
    CURRNET_STEP_COMPLETE: "CURRNET_STEP_COMPLETE",

    EDU_LEVEL: "EDU_LEVEL",
    SPECIALIZATION: "SPECIALIZATION",
    SPECIALIST_AREA: "SPECIALIST_AREA",

    CHANGE_USER: "USER",
    CHANGE_TOKEN: "TOKEN",
    SIGN_IN: "AUTHENTICATE",

    SET_ACTIVE_PAGE: 'SET_ACTIVE_PAGE',
    
    LANGUAGE: 'LANGUAGE'
}
import './AudioScript.scss';
import { useTranslation } from "react-i18next";
import {
    Flex,
    Text,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from '../../constants';
import { useNavigate } from 'react-router-dom';
import { FiChevronLeft } from "react-icons/fi";
import { SlideItem } from '../AudioScript/SlideItem'
import { cloneDeep } from 'lodash';

export const LeftSlideMiniatures = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sibling_scripts = useSelector((state: any) => state.sibling_scripts);
    const editable_scripts = useSelector((state: any) => state.editable_scripts);
    const memory_scripts = useSelector((state: any) => state.memory_scripts);

    const goBackUnSave = () => {
        dispatch({ type: CONSTANTS.SET_SIBLING_SCRIPTS, payload: cloneDeep(memory_scripts) })
        dispatch({ type: CONSTANTS.SET_EDITABLE_SCRIPTS, payload: cloneDeep(memory_scripts) })
        const edit_history: { [key: number]: any } = {}
        Object.keys(memory_scripts).forEach((key: any, index:number) => {
            edit_history[key] = {}
            Object.keys(memory_scripts[key]['siblings']).forEach((sibling_key: string, index:number) => {
                if (sibling_key !== 'thumbnail') {
                    const sibling_index = Number(sibling_key.split(' ')[1])
                    edit_history[key][sibling_index] = { "history": [], "used": 0 }
                    edit_history[key][sibling_index]['history'] = [memory_scripts[key]['siblings'][sibling_key]['text']]
                    edit_history[key][sibling_index]['used'] = 0
                }
            })
        })

        dispatch({ type: CONSTANTS.SET_EDIT_HISTORY, payload: cloneDeep(edit_history) })
        navigate('/smartslide');
    }

    const goBackSave = () => {
        dispatch({ type: CONSTANTS.SET_SIBLING_SCRIPTS, payload: cloneDeep(editable_scripts) })
        navigate('/smartslide');
    }

    return (
        <Flex className='page2-folienminiaturen'>
            <Flex className='page2-folienminiaturen-content'>
                <Flex className='page2-zuruck-top-button' onClick={goBackSave}>
                    <Flex className='page2-zuruck-top-button-group'>
                        <Flex className='page2-zuruck-top-button-group-inputleftelement'>
                            <FiChevronLeft className='page2-zuruck-top-button-group-inputleftelement-icon' />
                        </Flex>
                        <Flex className='page2-zuruck-top-button-group-input'>
                            <Flex className='page2-zuruck-top-button-group-frame4'>
                                <Text className='page2-zuruck-top-button-group-frame4-text'>{t('smartslide.back')}</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex className='page2-slide-items'>
                    {Object.keys(sibling_scripts).map((key, index) => (
                        <SlideItem key={index}
                            slide_index={index}
                        />
                    ))}
                </Flex>
            </Flex>
        </Flex >
    );
};
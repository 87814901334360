import { MutableRefObject, useEffect, useRef, useState } from 'react';
import './AudioScript.scss';
import { useTranslation } from "react-i18next";
import {
  Flex,
  Text,
  Box,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from '../../constants';
import { FiRotateCcw } from "react-icons/fi";
import { IoTimeOutline } from "react-icons/io5";
import { LeftSlideMiniatures } from "../AudioScript/LeftSlideMiniatures"
import { SlideFliedOfView } from "../AudioScript/SlideFliedOfView"
import { ScriptZone } from "../AudioScript/ScriptZone"
import { cloneDeep } from 'lodash';
import React from 'react';
import { estimateSentenceDuration, normalDurationFormat } from '../../helpers/duration_caculator';
import { apiMedia } from '../../helpers/api'
import store from '../../store';
import { CustomAlertDialog } from '../CustomAlert/CustomAlert'
import { VideoGeneratingPopUp } from '../VideoGeneratingPopUp/VideoGeneratingPopUp';
import { baseApiParams, setStore } from 'my-api-client-package';
import { requestRefreshToken, getAuthAccessObject } from 'my-api-client-package';
import { useNavigate } from 'react-router-dom';
import { toFrontendApp } from '../../helpers/forwarding'

export const AudioScript = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [videoStatus, setVideoStatus] = useState<'waiting' | 'generating' | 'completed'>('waiting');
  const videoLinkRef = useRef('');
  const [videoLink, setVideoLink] = useState<string>('');

  useEffect(() => {
    videoLinkRef.current = videoLink
  }, [videoLink]);
  useEffect(() => {
    if (getAuthAccessObject().token) {
      dispatch({ type: CONSTANTS.CONSTANTS_RATE_MY.CHANGE_TOKEN, payload: getAuthAccessObject().token })
    }
    const intervalId = setInterval(() => {
      let refreshtoken = requestRefreshToken(navigate);
      refreshtoken.then((resp: any) => dispatch({ type: CONSTANTS.CONSTANTS_RATE_MY.CHANGE_TOKEN, payload: resp.data.message }));
    }, 600000); // Every 10 minutes

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  const editable_scripts = useSelector((state: any) => state.editable_scripts);
  const memory_scripts = useSelector((state: any) => state.memory_scripts);

  const [duration, setDuration] = useState('xx:xx:xx');

  useEffect(() => {
    let scriptAll = ''
    let media_duration = 0
    Object.keys(editable_scripts).forEach((key: any, index: number) => {
      Object.keys(editable_scripts[key]['siblings']).forEach((skey: string, index: number) => {
        if (skey !== 'thumbnail') {
          scriptAll += (' ' + editable_scripts[key]['siblings'][skey]['text'])
          editable_scripts[key]['siblings'][skey]['media_elements'].forEach((media_item: any) => {
            media_duration += media_item['obj']['trim_duration']
          })
        }
      })

    })
    const text_duration = estimateSentenceDuration(scriptAll)
    setDuration(normalDurationFormat(text_duration + media_duration))
    // eslint-disable-next-line
  }, [JSON.stringify(editable_scripts)]);

  const saveScript = () => {
    dispatch({ type: CONSTANTS.SET_SIBLING_SCRIPTS, payload: cloneDeep(editable_scripts) })
  };

  // for save
  const { isOpen, onOpen, onClose } = useDisclosure()
  const makeVideoRef: MutableRefObject<any> = React.useRef()

  // for reset script
  const { isOpen: isResetOpen, onOpen: onResetOpen, onClose: onResetCLose } = useDisclosure()
  const resetRef: MutableRefObject<any> = React.useRef()

  const onNothing = () => {
    // No operation
  };

  const isCompletedRef = useRef(false);

  const makeVideo = async () => {
    setVideoLink('')
    setVideoStatus('generating');
    saveScript()
    const updatedState = store.getState();

    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    const formattedTime = `${date.getHours().toString().padStart(2, '0')}-${date.getMinutes().toString().padStart(2, '0')}-${date.getSeconds().toString().padStart(2, '0')}`;
    const uniqueName = `${updatedState.fileName}_${formattedDate}_${formattedTime}`;

    const data = {
      "pptx_id": updatedState.id,
      "uncomplete_elements": updatedState.sibling_scripts,
      "voice": updatedState.gender,
      "speed": updatedState.speed,
      "resolution": updatedState.resolution,
      "language": updatedState.language,
      "videoName": uniqueName
    }
    setStore(store, store.getState().token);
    const headers = baseApiParams()
    headers['headers']['server'] = process.env.REACT_APP_MEDIA_SERVER
    const thread_id = (await (await apiMedia.pptx2Video.generateCreate(data, headers)).json())['thread_id']
    isCompletedRef.current = false;
    while (!isCompletedRef.current) {
      const state = (await (await apiMedia.checkStatus.checkStatusDetail(thread_id, headers)).json())['status'];

      if (state === 'Running') {
        setVideoStatus('generating');
        console.log('running');
      } else if (state === 'Completed') {
        const result = (await (await apiMedia.getResult.getResultDetail(thread_id, headers)).json())['message'];
        console.log(result)
        isCompletedRef.current = true;
        setVideoStatus('completed');
        const vl = await toFrontendApp(result['video_obj']['workflow_id'])
        setVideoLink(vl)
      } else {
        setVideoStatus('waiting');
        console.log(`error: ${state}`);
        isCompletedRef.current = true;
      }

      if (!isCompletedRef.current) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
    // onClose()
  }

  const onDoneVideo = () => {
    setVideoStatus('waiting')
    onClose()
    navigate('/smartslide')
  }

  const onGotoVideo = () => {
    window.open(videoLink, "_blank");
  }

  const onBreakVideoGeneration = () => {
    setVideoStatus('waiting')
    isCompletedRef.current = true;
    
    // need to send a request to delete video obj in storage
    onClose()
  }


  const resetAllScript = () => {
    dispatch({ type: CONSTANTS.SET_SIBLING_SCRIPTS, payload: cloneDeep(memory_scripts) })
    dispatch({ type: CONSTANTS.SET_EDITABLE_SCRIPTS, payload: cloneDeep(memory_scripts) })
    const edit_history: { [key: number]: any } = {}
    Object.keys(memory_scripts).forEach((key: any, index: number) => {
      edit_history[key] = {}
      Object.keys(memory_scripts[key]['siblings']).forEach((sibling_key: string, index: number) => {
        if (sibling_key !== 'thumbnail') {
          const sibling_index = Number(sibling_key.split(' ')[1])
          edit_history[key][sibling_index] = { "history": [], "used": 0 }
          edit_history[key][sibling_index]['history'] = [memory_scripts[key]['siblings'][sibling_key]['text']]
          edit_history[key][sibling_index]['used'] = 0
        }
      })
    })

    dispatch({ type: CONSTANTS.SET_EDIT_HISTORY, payload: cloneDeep(edit_history) })
    resetRef.current.click()
  }

  return (
    <Box className='page2-pptx2video-audio-script-page'>
      <Flex className='page2-content'>
        <LeftSlideMiniatures />
        <SlideFliedOfView />
        <ScriptZone />
      </Flex>

      <Flex className='page2-footer'>
        <Flex className='page2-zurucksetzen'>
          <Flex className='page2-zuruck-button' onClick={onResetOpen}>
            <FiRotateCcw className='page2-zuruck-button-icon' />
            <Text className='page2-zuruck-button-text'>{t('smartslide.reset_all')}</Text>
          </Flex>
          <CustomAlertDialog
            leastDestructiveRef={resetRef}
            onClose={onResetCLose}
            isOpen={isResetOpen}
            topbuttonfunction={onResetCLose}
            bottombuttonfunction={resetAllScript}
            content={[t('smartslide.reset_script_title'), t('smartslide.reset_script_warning'), t('smartslide.reset_script_undo'), t('smartslide.reset_script_do')]}
            level='warning'
          />
        </Flex>

        <Flex className='page2-focontent'>
          <Flex className='page2-pos-text'>
            <Flex className='page2-time'>
              <Flex className='page2-pos-icon'>
                <Tooltip className='tooltip' closeOnClick={false} label={t('smartslide.total_time_hint')}>
                  <Box>
                    <IoTimeOutline className='page2-time-icon' />
                  </Box>
                </Tooltip>
                <Text className='page2-time-time'>{duration}</Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex className='page2-video-button' onClick={onOpen}>
            <Text className='page2-create-video'>{t('smartslide.create_video')}</Text>
          </Flex>
          {
            videoStatus === 'waiting' ? (
              <CustomAlertDialog
                leastDestructiveRef={makeVideoRef}
                onClose={onClose}
                isOpen={isOpen}
                topbuttonfunction={makeVideo}
                bottombuttonfunction={onClose}
                content={[t('smartslide.make_video_title'), t('smartslide.make_video_warning'), t('smartslide.sure_make_video'), t('smartslide.cancel_make_video')]}
                level='info'
              />
            ) : videoStatus === 'generating' ? (
              <VideoGeneratingPopUp
                leastDestructiveRef={makeVideoRef}
                onClose={onClose}
                isOpen={isOpen}
              />
            ) : (
              <CustomAlertDialog
                leastDestructiveRef={makeVideoRef}
                onClose={onClose}
                isOpen={isOpen}
                topbuttonfunction={onGotoVideo}
                bottombuttonfunction={onDoneVideo}
                content={[
                  t('smartslide.make_video_done_title'),
                  <Box>
                    <Text dangerouslySetInnerHTML={{ __html: t('smartslide.make_video_done_message').replace(/\n/g, '<br />') }} />
                  </Box>,
                  t('smartslide.sure_make_video_done'),
                  t('smartslide.close_make_video_done')
                ]}
                level='info'
              />
            )
          }
        </Flex>
      </Flex>
    </Box>
  );
};
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://192.168.178.80:5000";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title A swagger API
 * @version 0.0.1
 * @baseUrl http://192.168.178.80:5000
 *
 * powered by Flasgger
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  audio = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name AttachCreate
     * @summary Attach given audios at given times to given video file.
     * @request POST:/audio/attach/
     */
    attachCreate: (
      body: {
        /** Directory of all audio files. */
        audio_files?: string;
        /** Text file with all audio timings. */
        audio_times?: string;
        /** Path of the video file. */
        video_file?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/audio/attach/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Third party
     * @name CreateCreate
     * @summary Create a new audio file based on a text.
     * @request POST:/audio/create/
     */
    createCreate: (
      body: {
        /** Output path to determine where to store results. */
        output_path?: string;
        /** Text that should be converted to audio. */
        text?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/audio/create/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Third party
     * @name ShortenCreate
     * @summary Shorten a given text with a time value to a new time limit.
     * @request POST:/audio/shorten/
     */
    shortenCreate: (
      body: {
        /** New time limit in seconds. */
        limit?: string;
        /** Current text. */
        text?: string;
        /** Current time in seconds of the audio file which is the converted text. */
        time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/audio/shorten/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  blockcut = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name ProcessingCreate
     * @summary Generate a Blockcutting command from processing object.
     * @request POST:/blockcut/processing/
     */
    processingCreate: (
      body: {
        /** Processing object. */
        processing?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/blockcut/processing/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  checkStatus = {
    /**
     * No description
     *
     * @tags Threads
     * @name CheckStatusDetail
     * @summary Check the status of a thread with a unique thread id.
     * @request GET:/check_status/{thread_id}/
     */
    checkStatusDetail: (threadId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/check_status/${threadId}/`,
        method: "GET",
        ...params,
      }),
  };
  concatvideo = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name ConcatvideoCreate
     * @summary Concat session videos based on a given directory.
     * @request POST:/concatvideo/
     */
    concatvideoCreate: (
      body: {
        /** The directory where all files to be concatenated are located. */
        directory?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/concatvideo/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  create = {
    /**
     * No description
     *
     * @tags Backend
     * @name ThumbnailCreate
     * @summary Create a thumbnail of a pdf or image.
     * @request POST:/create/thumbnail/
     */
    thumbnailCreate: (
      body: {
        /** The file path of the base file which will be used as a thumbnail. */
        file_path?: string;
        /** The id of the media file which should get a new thumbnail. */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/create/thumbnail/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backend
     * @name ThumbnailUploadedCreate
     * @summary Create a thumbnail of a pdf or image.
     * @request POST:/create/thumbnail/uploaded/
     */
    thumbnailUploadedCreate: (
      body: {
        /** The id of the media file which should get a new thumbnail. */
        id?: string;
        /** The download url of the media file which should get a new thumbnail. */
        url?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/create/thumbnail/uploaded/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  createmultiview = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name CreatemultiviewCreate
     * @summary Create a multiview from four videos with provided offsets.
     * @request POST:/createmultiview/
     */
    createmultiviewCreate: (
      body: {
        /** The offset in milliseconds for the first video. */
        offset1?: string;
        /** The offset in milliseconds for the second video. */
        offset2?: string;
        /** The offset in milliseconds for the third video. */
        offset3?: string;
        /** The offset in milliseconds for the fourth video. */
        offset4?: string;
        /** Output path to determine where to store results. */
        output_path?: string;
        /** The resolution of the output file. */
        resolution?: string;
        /** The file path of the first video. */
        vid1_path?: string;
        /** The file path of the second video. */
        vid2_path?: string;
        /** The file path of the third video. */
        vid3_path?: string;
        /** The file path of the fourth video. */
        vid4_path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/createmultiview/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  createvideo = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name CreatevideoCreate
     * @summary Create a new video based on a provided cutting file.
     * @request POST:/createvideo/
     */
    createvideoCreate: (
      body: {
        /** The cutting text file to be used for creating the video. It should contain video path and timestamps. */
        file_path?: string;
        /** Output path to determine where to store results. */
        output_path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/createvideo/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  getResult = {
    /**
     * No description
     *
     * @tags Threads
     * @name GetResultDetail
     * @summary Get the result of a thread with a unique thread id.
     * @request GET:/get_result/{thread_id}/
     */
    getResultDetail: (threadId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/get_result/${threadId}/`,
        method: "GET",
        ...params,
      }),
  };
  import = {
    /**
     * No description
     *
     * @tags Excel
     * @name WorkflowCreate
     * @summary Read Excel file into cache.
     * @request POST:/import/workflow/
     */
    workflowCreate: (
      body: {
        /** File path of the Excel file that should be uploaded. */
        file_path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/import/workflow/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Excel
     * @name WorkflowLinkageCreate
     * @summary Complete excel import with linked persons and persongroups.
     * @request POST:/import/workflow/linkage/
     */
    workflowLinkageCreate: (
      body: {
        /** File path of the cutting file if segments should be cut. */
        cutting_file_path?: string;
        /** Persongroups connected to an existing id if it exists already. */
        pglinks?: string;
        /** Persons connected to an existing id if it exists already. */
        plinks?: string;
        /** Id of the video which content of excel file are supposed to be added to. */
        video?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/import/workflow/linkage/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Excel
     * @name WorkflowValidateCreate
     * @summary Validate if an excel file is valid to upload.
     * @request POST:/import/workflow/validate/
     */
    workflowValidateCreate: (
      body: {
        /** File path of the Excel file that should be checked. */
        file_path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/import/workflow/validate/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  metadata = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name SynchpointList
     * @summary Synch four videos based on metadata.
     * @request GET:/metadata/synchpoint/
     */
    synchpointList: (
      body: {
        /** The file path of the first video. */
        vid1?: string;
        /** The file path of the second video. */
        vid2?: string;
        /** The file path of the third video. */
        vid3?: string;
        /** The file path of the fourth video. */
        vid4?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/metadata/synchpoint/`,
        method: "GET",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  pptx2Video = {
    /**
     * No description
     *
     * @tags PPTX2VIDEO
     * @name GenerateCreate
     * @summary Generate a video from an incomplete processing object of one slide PPTX.
     * @request POST:/pptx2video/generate/
     */
    generateCreate: (
      body: {
        /** The ID of the PowerPoint file. */
        pptx_id?: string;
        /** parser results from windwos backed. */
        uncomplete_elements?: {
          /** List of media elements associated with this sibling. */
          media_elements?: {
            obj?: {
              /** The end point of the media playback (in seconds). */
              end_point?: number;
              /** The ID of the media element. */
              media_id?: string;
              /** The name of the media element. */
              object_name?: string;
              /** The type of media (e.g., Video, Audio). */
              object_type?: string;
              /** The start point of the media playback (in seconds). */
              start_point?: number;
              /** The duration of the trimmed media playback (in seconds). */
              trim_duration?: number;
            };
          }[];
          /** The ID of the associated picture. */
          picture?: string;
          /** The demo script or text associated with this sibling. */
          text?: string;
        }[];
        /** female or male voice. */
        voice?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/pptx2video/generate/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PPTX2VIDEO
     * @name PptxUploadCreate
     * @summary Upload pptx to storage
     * @request POST:/pptx2video/pptxUpload/
     */
    pptxUploadCreate: (
      body: {
        /**
         * The PPTX file to be uploaded.
         * @format binary
         */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/pptx2video/pptxUpload/`,
        method: "POST",
        body: body,
        type: ContentType.FormData,
        ...params,
      }),
  };
  processvideo = {
    /**
     * No description
     *
     * @tags App
     * @name ProcessvideoCreate
     * @summary Cut a video based on processing object.
     * @request POST:/processvideo/
     */
    processvideoCreate: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/processvideo/`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FFMPEG
     * @name HostedCreate
     * @summary Cut a video based on a processing object.
     * @request POST:/processvideo/hosted/
     */
    hostedCreate: (
      body: {
        /** Processing object that contains information to cut the video. */
        processing?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/processvideo/hosted/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  screenshot = {
    /**
     * No description
     *
     * @tags FFMPEG
     * @name ScreenshotCreate
     * @summary Create a screenshot of a video based on a provided video file and time.
     * @request POST:/screenshot/
     */
    screenshotCreate: (
      body: {
        /** The file path of the video path. */
        file_path?: string;
        /** The time in seconds of when the screenshot should be created. */
        time?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/screenshot/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  settings = {
    /**
     * No description
     *
     * @tags App
     * @name SettingsList
     * @summary Get all public Settings.
     * @request GET:/settings/
     */
    settingsList: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags App
     * @name SettingsCreate
     * @summary Change a Setting.
     * @request POST:/settings/
     */
    settingsCreate: (
      body: {
        /** Name of the setting that should be changed. */
        name?: string;
        /** Value of the setting that should be changed. */
        value?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/settings/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  video = {
    /**
     * No description
     *
     * @tags Backend
     * @name VideoCreate
     * @summary Upload a video or an image to digital ocean via the backend.
     * @request POST:/video/
     */
    videoCreate: (
      body: {
        /** Description which should be connected to the file. */
        description?: string;
        /** File path of the file that should be uploaded. */
        file_path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, void>({
        path: `/video/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
}

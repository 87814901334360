import { useEffect, useState } from 'react';
import './AudioScript.scss';
import {
    Flex,
    Text,
    Tooltip
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from '../../constants';
import { FiChevronDown } from "react-icons/fi";
import { FiFilm, FiMusic } from "react-icons/fi";
import { BiTime } from "react-icons/bi";
import React from 'react';
import { estimateSentenceDuration, normalDurationFormat } from '../../helpers/duration_caculator';
import { DownloadMedia } from '../../helpers/download'
import { useTranslation } from 'react-i18next';

// interface Sibling {
//     media_elements: any[];
//     picture: string;
//     text: string;
// }

// interface DemoObject {
//     demo_plan: string;
//     siblings: {
//         thumbnail: string;
//         [key: string]: Sibling | string;
//     };
// }

interface SlideItemProps {
    slide_index: number;
}

export const SlideItem: React.FC<SlideItemProps> = ({
    slide_index
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const current_slide = useSelector((state: any) => state.current_slide);
    const editable_scripts = useSelector((state: any) => state.editable_scripts);
    console.log(editable_scripts)

    const [duration, setDuration] = useState('xx:xx:xx');

    useEffect(() => {
        let scriptSlide = ''
        let media_duration = 0
        Object.keys(editable_scripts[slide_index]['siblings']).forEach((key: string, index: number) => {
            if (key !== 'thumbnail') {
                scriptSlide += (' ' + editable_scripts[slide_index]['siblings'][key]['text'])
                editable_scripts[slide_index]['siblings'][key]['media_elements'].forEach((media_item: any) => {
                    media_duration += media_item['obj']['trim_duration']
                })
            }
        })
        const text_duration = estimateSentenceDuration(scriptSlide)
        setDuration(normalDurationFormat(text_duration + media_duration))
    }, [JSON.stringify(editable_scripts[slide_index])]);

    const [thumbnails, setThumbnails] = useState<any>({});

    const getPicture = async (pictureId: string): Promise<string> => {
        const pictureUrl = await DownloadMedia(pictureId);
        return pictureUrl
    }

    useEffect(() => {
        const fetchThumbnails = async () => {
            const newThumbnails: any = {};
            newThumbnails['thumbnail'] = await getPicture(editable_scripts[slide_index].siblings.thumbnail);
            for (const key of Object.keys(editable_scripts[slide_index].siblings)) {
                if (key !== 'thumbnail') {
                    const thumbnailUrl = await getPicture(editable_scripts[slide_index].siblings[key].picture);
                    newThumbnails[key] = thumbnailUrl;
                }
            }
            setThumbnails(newThumbnails);
        };

        fetchThumbnails();
    }, [editable_scripts[slide_index].siblings]);

    const clickOnMedia = async (mediaId: string) => {
        const mediaUrl = await DownloadMedia(mediaId);
        window.open(mediaUrl)
    }

    return (

        (current_slide[0] !== slide_index) ?
            (
                <Flex className='page2-slide-item-close' onClick={() => dispatch({ type: CONSTANTS.SET_CURRENT_SLIDE, payload: [slide_index, 0] })}>
                    <Flex className='page2-slide-item-close-no'>
                        <Text className='page2-slide-item-close-text'>{slide_index + 1}</Text>
                    </Flex>
                    <Flex className='page2-slide-item-close-frame-hover'>
                        <Flex className='page2-slide-item-close-frame-image' style={{ backgroundImage: `url(${thumbnails['thumbnail']})` }}></Flex>
                    </Flex>
                    <Flex className='page2-slide-item-close-arrow-unfold'></Flex>
                </Flex>
            )
            :
            (
                <Flex className='page2-slide-item-open'>
                    <Flex className='page2-slide-item-open-pos-content'>
                        <Flex className='page2-slide-item-open-main-folie'>
                            <Flex className='page2-slide-item-open-main-folie-no'>
                                <Text className='page2-slide-item-open-main-folie-no-text'>{slide_index + 1}</Text>
                            </Flex>
                            <Flex className='page2-slide-item-open-main-folie-framehover'>
                                <Flex className='page2-slide-item-open-main-folie-framehover-image' style={{ backgroundImage: `url(${thumbnails['thumbnail']})` }}></Flex>
                            </Flex>
                            <Flex className='page2-slide-item-open-main-folie-arrowunfold'>
                                <FiChevronDown className='page2-slide-item-open-main-folie-arrowunfold-icon' />
                            </Flex>
                        </Flex>

                        <Flex className='page2-slide-item-open-siblingItems'>
                            {Object.keys(editable_scripts[slide_index].siblings).map((key, index) => (
                                key !== 'thumbnail' ?
                                    (
                                        <Flex key={index} className='page2-slide-item-open-siblingItem' onClick={() => dispatch({ type: CONSTANTS.SET_CURRENT_SLIDE, payload: [slide_index, index] })}>
                                            <Flex className='page2-slide-item-open-siblingItem-no'>
                                                <Text className='page2-slide-item-open-siblingItem-no-text'>{slide_index + 1}.{index + 1}</Text>
                                                <Flex className='page2-slide-item-open-siblingItem-media'>
                                                    {
                                                        editable_scripts[slide_index].siblings['SIBLING ' + String(index)]['media_elements'].map((mediaObj: any, index: number) => (
                                                            mediaObj['obj']['online'] ?
                                                                (
                                                                    mediaObj['obj'].object_type === 'Video' ?
                                                                        (
                                                                            <Tooltip key={index} className='tooltip' label={t('smartslide.video_hint')}>
                                                                                <Flex>
                                                                                    <FiFilm className='media-tag-icon' onClick={() => { window.open(mediaObj['obj']['media_id'], '_blank') }} />
                                                                                </Flex>
                                                                            </Tooltip>
                                                                        )
                                                                        :
                                                                        (
                                                                            <Tooltip key={index} className='tooltip' label={t('smartslide.audio_hint')}>
                                                                                <Flex>
                                                                                    <FiMusic className='media-tag-icon' onClick={() => { window.open(mediaObj['obj']['media_id'], '_blank') }} />
                                                                                </Flex>
                                                                            </Tooltip>
                                                                        )
                                                                )
                                                                :
                                                                (
                                                                    mediaObj['obj'].object_type === 'Video' ?
                                                                        (
                                                                            <Tooltip key={index} className='tooltip' label={t('smartslide.video_hint')}>
                                                                                <Flex>
                                                                                    <FiFilm className='media-tag-icon' onClick={() => { clickOnMedia(mediaObj['obj']['media_id']) }} />
                                                                                </Flex>
                                                                            </Tooltip>
                                                                        )
                                                                        :
                                                                        (
                                                                            <Tooltip key={index} className='tooltip' label={t('smartslide.audio_hint')}>
                                                                                <Flex>
                                                                                    <FiMusic className='media-tag-icon' onClick={() => { clickOnMedia(mediaObj['obj']['media_id']) }} />
                                                                                </Flex>
                                                                            </Tooltip>
                                                                        )
                                                                )
                                                        ))
                                                    }
                                                </Flex>
                                            </Flex>
                                            <Flex className={current_slide[1] === index ? 'page2-slide-item-open-siblingItem-frame49-selected' : 'page2-slide-item-open-siblingItem-frame49'}>
                                                <Flex className='page2-slide-item-open-siblingItem-frame49-image' style={{ backgroundImage: `url(${thumbnails['SIBLING ' + String(index)]})` }}></Flex>
                                            </Flex>
                                            <Flex className='page2-slide-item-open-siblingItem-media-pos'>
                                                {/* placeholder */}
                                            </Flex>
                                        </Flex>
                                    )
                                    :
                                    (
                                        <React.Fragment key={index}></React.Fragment>
                                    )
                            ))}
                        </Flex>
                    </Flex>

                    <Flex className='page2-slide-item-open-pos-time'>
                        <Flex className='page2-slide-item-open-pos-time-time'>
                            <Flex className='page2-slide-item-open-pos-time-time-pos-icon'>
                                <BiTime className='page2-slide-item-open-pos-time-time-pos-icon-icon' />
                            </Flex>
                            <Text className='page2-slide-item-open-pos-time-time-text'>{duration}</Text>
                        </Flex>
                    </Flex>
                </Flex>
            )
    );
};
// App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Pptx2video } from './components/Pptx2video/Main'
import { AudioScript } from './components/AudioScript/Main'
import { Login } from './components/login/Login'
import { setStore } from 'my-api-client-package';
import store from "./store";
import ProtectedRoute from './components/login/ProtectedRoute';

setStore(store, "smart_slide_auth_access");

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route
                    path="/smartslide"
                    element={
                        <ProtectedRoute>
                            <Pptx2video />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/smartslide/audioScript"
                    element={
                        <ProtectedRoute>
                            <AudioScript />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
    );
};

export default App;

import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/assets/styles/main.scss';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import App from './App';
import './i18n';

import { Provider } from 'react-redux';
import store from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const customProgressTheme = extendTheme({
  components: {
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: '#81E6D9',
        },
        track: {
          bg: '#EDF2F7', 
        },
      },
    },
    Tooltip: {
      baseStyle: {
        bg: '#171923',
        color: 'white',
        borderRadius: '4px', 
        padding: '2px 8px',
      },
    }
  },
});

root.render(
  <Provider store={store}>
    <ChakraProvider theme={customProgressTheme}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ChakraProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { apiMedimesh } from './api'
import { baseApiParams } from 'my-api-client-package';

export const DownloadMedia = async (mediaId: string) => {
    try {
        const response = await apiMedimesh.download.getMediaDownload(mediaId, baseApiParams());
        const mediaUrl = (await response.json())['message'];
        
        return mediaUrl;

    } catch (e) {
        console.error("Error:", e);
    }
};

export const DownloadVideoMedia = async (videoId: string) => {
    try {
        const response = await apiMedimesh.video.getVideo(videoId+'/media', baseApiParams());
        const mediaUrl = (await response.json())['media']['url'];
        console.log(mediaUrl)
        return mediaUrl;

    } catch (e) {
        console.error("Error:", e);
    }
};
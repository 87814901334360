/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://20.218.163.191:5000";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title My API
 * @version 1.0.0
 * @baseUrl http://20.218.163.191:5000
 *
 * API for my Flask app
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  debug = {
    /**
     * No description
     *
     * @name AiDemoCreate
     * @summary Generate an AI demo script based on slide content and parameters.
     * @request POST:/debug/aiDemo/
     */
    aiDemoCreate: (
      body: {
        /** Set the AI-generated answer or script for the slide. */
        ai_answer?: string;
        /**
         * The AI model to be used for generating the demo script.
         * @default "gpt-4o"
         */
        chatgpt_model?: string;
        /** Feedback to improve the generated script. */
        feedback?: string;
        /**
         * The language of the generated script.
         * @default "English"
         */
        language?: string;
        /** The ID of the PowerPoint file. */
        pptx_id?: string;
        /** The index of the slide to be processed. */
        slide_index?: number;
        /** The total number of slides in the presentation. */
        slide_sum?: number;
        /**
         * The topic of the demo.
         * @default "unknown"
         */
        topic?: string;
        /** Use notes to generate answer or not. */
        useNotes?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          message?: {
            /** The generated AI demo plan. */
            demo_plan?: string;
            /** Sibling details for each part of the demo. */
            siblings?: Record<
              string,
              {
                /** List of media elements associated with this sibling. */
                media_elements?: {
                  obj?: {
                    /** The end point of the media playback (in seconds). */
                    end_point?: number;
                    /** The ID of the media element. */
                    media_id?: string;
                    /** The name of the media element. */
                    object_name?: string;
                    /** The type of media (e.g., Video, Audio). */
                    object_type?: string;
                    /** The start point of the media playback (in seconds). */
                    start_point?: number;
                    /** The duration of the trimmed media playback (in seconds). */
                    trim_duration?: number;
                  };
                }[];
                /** The ID of the associated picture. */
                picture?: string;
                /** The demo script or text associated with this sibling. */
                text?: string;
              }
            >;
          };
        },
        void
      >({
        path: `/debug/aiDemo/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ParserCreate
     * @summary Parse and process a specific slide from a PowerPoint file.
     * @request POST:/debug/parser/
     */
    parserCreate: (
      body: {
        /** The height to resize the slide. */
        height?: number;
        /** The ID of the PowerPoint file. */
        pptx_id?: string;
        /** The index of the slide to be processed. */
        slide_index?: number;
        /** The width to resize the slide. */
        width?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** The result of the operation. */
          result?: string;
        },
        void
      >({
        path: `/debug/parser/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name StorageCreate
     * @summary Save temporary video and screenshot data to storage.
     * @request POST:/debug/storage/
     */
    storageCreate: (
      body: {
        /** The ID of the PowerPoint file. */
        pptx_id?: string;
        /** The index of the slide whose media is being processed. */
        slide_index?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Updated sibling timestamps with media links. */
          sibling_timestamps?: object;
        },
        void
      >({
        path: `/debug/storage/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  pptx2Video = {
    /**
     * @description This endpoint accepts one or multiple PDF files, stores them locally, and builds a RAG (Retrieval-Augmented Generation) vector database for further processing.
     *
     * @tags PPTX2VIDEO
     * @name BuildRagCreate
     * @summary Build RAG vector database using pdf files
     * @request POST:/pptx2video/buildRag/
     */
    buildRagCreate: (
      data: {
        /**
         * Unique ID of the PPTX2VIDEO task.
         * @example "xxxxxxxx"
         */
        pptx_id: string;
        /** The PDF files to upload (can be one or multiple files). */
        file: File[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @example "success" */
          message?: string;
        },
        | {
            /** @example "No file part in the request" */
            error?: string;
          }
        | {
            /** @example "Internal server error" */
            error?: string;
          }
      >({
        path: `/pptx2video/buildRag/`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PPTX2VIDEO
     * @name ResultDetail
     * @summary Retrieve the result of a completed thread based on the thread ID.
     * @request GET:/pptx2video/result/{thread_id}/
     */
    resultDetail: (threadId: string, params: RequestParams = {}) =>
      this.request<
        {
          message?: {
            /** The generated AI demo plan. */
            demo_plan?: string;
            /** Sibling details for each part of the demo. */
            siblings?: Record<
              string,
              {
                /** List of media elements associated with this sibling. */
                media_elements?: {
                  obj?: {
                    /** The end point of the media playback (in seconds). */
                    end_point?: number;
                    /** The ID of the media element. */
                    media_id?: string;
                    /** The name of the media element. */
                    object_name?: string;
                    /** The type of media (e.g., Video, Audio). */
                    object_type?: string;
                    /** The start point of the media playback (in seconds). */
                    start_point?: number;
                    /** The duration of the trimmed media playback (in seconds). */
                    trim_duration?: number;
                  };
                }[];
                /** The ID of the associated picture. */
                picture?: string;
                /** The demo script or text associated with this sibling. */
                text?: string;
              }
            >;
          };
        },
        void
      >({
        path: `/pptx2video/result/${threadId}/`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PPTX2VIDEO
     * @name SlideAudioScriptDelete
     * @summary Delete the agent-generated audio script for a given PowerPoint slide. If slide_index is -1, all audio scripts for the given pptx_id will be deleted.
     * @request DELETE:/pptx2video/slideAudioScript
     */
    slideAudioScriptDelete: (
      query: {
        /** The unique identifier for the PowerPoint file. */
        pptx_id: string;
        /** The index of the slide whose audio script is to be deleted. Use -1 to delete audio scripts for all slides. */
        slide_index: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** True indicating successful deletion. */
          message?: boolean;
        },
        | {
            /** Error message indicating missing parameters or invalid slide_index. */
            error?: string;
          }
        | {
            /** General error message. */
            error?: string;
            /** Detailed error message for debugging. */
            message?: string;
          }
      >({
        path: `/pptx2video/slideAudioScript`,
        method: "DELETE",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PPTX2VIDEO
     * @name SlideAudioScriptCreate
     * @summary Generate an incomplete processing object of one slide pptx.
     * @request POST:/pptx2video/slideAudioScript/
     */
    slideAudioScriptCreate: (
      body: {
        /** Set the AI-generated answer or script for the slide. */
        ai_answer?: string;
        /**
         * The AI model to be used for generating the demo script.
         * @default "gpt-4o"
         */
        chatgpt_model?: string;
        /** Feedback to improve the generated script. */
        feedback?: string;
        /** The height to resize the slide. */
        height?: number;
        /**
         * The language of the generated script.
         * @default "English"
         */
        language?: string;
        /** The ID of the PowerPoint file. */
        pptx_id?: string;
        /** The index of the slide to be processed. */
        slide_index?: number;
        /** The total number of slides in the presentation. */
        slide_sum?: number;
        /**
         * The topic of the demo.
         * @default "unknown"
         */
        topic?: string;
        /** Use notes to generate answer or not. */
        useNotes?: boolean;
        /** The width to resize the slide. */
        width?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          date_started?: string;
          message?: string;
          status?: string;
          thread_id?: string;
        },
        void
      >({
        path: `/pptx2video/slideAudioScript/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PPTX2VIDEO
     * @name SlideParserCreate
     * @summary Parse the slide.
     * @request POST:/pptx2video/slideParser/
     */
    slideParserCreate: (
      body: {
        /** The height to resize the slide. */
        height?: number;
        /** The ID of the PowerPoint file. */
        pptx_id?: string;
        /** The index of the slide to be processed. */
        slide_index?: number;
        /** The total number of slides in the presentation. */
        slide_sum?: number;
        /** The width to resize the slide. */
        width?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          date_started?: string;
          message?: string;
          status?: string;
          thread_id?: string;
        },
        void
      >({
        path: `/pptx2video/slideParser/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name SlideSumDetail
     * @summary Retrieve the total number of slides in a PowerPoint file.
     * @request GET:/pptx2video/slideSum/{pptx_id}/
     */
    slideSumDetail: (pptxId: string, params: RequestParams = {}) =>
      this.request<
        {
          number?: number;
          thumbnail?: string;
        },
        void
      >({
        path: `/pptx2video/slideSum/${pptxId}/`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PPTX2VIDEO
     * @name StatusDetail
     * @summary Retrieve the status of a running thread based on the thread ID.
     * @request GET:/pptx2video/status/{thread_id}/
     */
    statusDetail: (threadId: string, params: RequestParams = {}) =>
      this.request<
        {
          /** The current status of the thread. */
          message?: string;
        },
        void
      >({
        path: `/pptx2video/status/${threadId}/`,
        method: "GET",
        ...params,
      }),

    /**
     * @description If a specific slide_index is provided, only that slide's resources will be deleted. <br/>If slide_index is -1, all slides associated with the given pptx_id will be deleted.<br/>
     *
     * @tags PPTX2VIDEO
     * @name TempFilesDelete
     * @summary Delete temporary files associated with the parsing of a PowerPoint slide and the agent answer.
     * @request DELETE:/pptx2video/tempFiles
     */
    tempFilesDelete: (
      query: {
        /** The unique identifier for the PowerPoint file. */
        pptx_id: string;
        /** The index of the slide to delete. If set to -1, all slides associated with the pptx_id will be deleted. */
        slide_index: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Deletion result with details. */
          message?: {
            /** Whether the local temp files were successfully deleted. */
            local?: boolean;
            /** Status of deletion for each storage ID. */
            storage_id?: string;
          };
        },
        | {
            /** Error message indicating missing parameters. */
            error?: string;
          }
        | {
            /** General error message. */
            error?: string;
            /** Detailed error message for debugging. */
            message?: string;
          }
      >({
        path: `/pptx2video/tempFiles`,
        method: "DELETE",
        query: query,
        format: "json",
        ...params,
      }),
  };
}

import './Pptx2video.scss';
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  Heading,
  Input,
  Flex,
  Text,
  Textarea,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
} from '@chakra-ui/react';
import { IoChevronDown } from "react-icons/io5";
import { FiInfo, FiUpload, FiFileText, FiTrash2 } from "react-icons/fi";
import { useSelector, useDispatch } from 'react-redux';
import * as CONSTANTS from '../../constants';
import { Key, MutableRefObject, useRef } from 'react';

interface LeftSettingSideProps {
  resetAll: () => Promise<void>
}

export const LeftSettingSide: React.FC<LeftSettingSideProps> = ({
  resetAll
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const language = useSelector((state: any) => state.language);
  const gender = useSelector((state: any) => state.gender);
  const speed = useSelector((state: any) => state.speed);
  const resolution = useSelector((state: any) => state.resolution);
  const notesIncluded = useSelector((state: any) => state.notesIncluded);
  const specification = useSelector((state: any) => state.specification);
  const pdfFiles = useSelector((state: any) => state.pdfFiles);

  const inputDocumentRef: MutableRefObject<any> = useRef(null);

  const setLanguageHandler = (lang: any) => {
    dispatch({ type: CONSTANTS.SET_LANGUAGE, payload: lang });
  };

  const setGenderHandler = (gender: any) => {
    dispatch({ type: CONSTANTS.SET_GENDER, payload: gender });
  };

  const setSpeedHandler = (speedKey: any) => {
    dispatch({ type: CONSTANTS.SET_SPEED, payload: speedKey });
  };

  const setResolutionHandler = (res: any) => {
    dispatch({ type: CONSTANTS.SET_RESOLUTION, payload: res });
  };

  const toggleNotesIncluded = () => {
    dispatch({ type: CONSTANTS.SET_NOTES_INCLUDED, payload: !notesIncluded });
  };

  const setSpecificationHandler = (text: any) => {
    dispatch({ type: CONSTANTS.SET_SPECIFICATION, payload: text });
  };

  const removePdfFile = (index: any) => {
    const newPdfFiles = pdfFiles.filter((_: any, i: any) => i !== index);
    dispatch({ type: CONSTANTS.SET_PDF_FILES, payload: newPdfFiles });
  };

  const handleDocUploadButtonClick = () => {
    if (inputDocumentRef.current) {
      inputDocumentRef.current.click();
    }
  };

  const handleViewPdf = (pdfFile: Blob | MediaSource) => {
    if (pdfFile instanceof File) {
      const fileUrl = URL.createObjectURL(pdfFile);
      window.open(fileUrl, '_blank');
    } else {
      // console.error('Invalid file type');
    }
  };

  const handlePdfUpload = (event: any) => {
    const selectedFiles: File[] = Array.from(event.target.files);
    dispatch({
      type: CONSTANTS.SET_PDF_FILES,
      payload: [...pdfFiles, ...selectedFiles]
    });
  };

  const formatFileSize = (size: number) => {
    const i: number = Math.floor(Math.log(size) / Math.log(1024));
    const formattedSize = (size / Math.pow(1024, i)).toFixed(2);
    return `${formattedSize} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
  };

  return (
    <Flex className='content-left-side'>
      <Flex className='left-side'>
        <Flex className='header'>
          <Heading className='voreinstellungen'>{t('smartslide.preferences')}</Heading>
          <Tooltip className='tooltip' closeOnClick={false} label={t('smartslide.set_default_hint')}>
            <Box className='zurucksetzen-button' onClick={() => resetAll()}>
              <Text className='zurucksetzen-text'>{t('smartslide.set_default')}</Text>
            </Box>
          </Tooltip>
        </Flex>

        <Flex className='sprache'>

          <Flex className='dropdown-sprache'>
            <Menu matchWidth={true}>
              <MenuButton className={language === '' ? "MenuButton" : "MenuButton-selected"}>
                <Flex className='menu-button'>
                  <Text className='children'>{language === '' ? t('smartslide.language') : language}</Text>
                  <IoChevronDown className='right-icon' />
                </Flex>
              </MenuButton>
              <MenuList className='menu-list'>
                <MenuItem className='menu-item' onClick={() => setLanguageHandler(t('smartslide.german'))}>{t('smartslide.german')}</MenuItem>
                <MenuItem className='menu-item' onClick={() => setLanguageHandler(t('smartslide.english'))}>{t('smartslide.english')}</MenuItem>
              </MenuList>
            </Menu>
          </Flex>

          <Flex className='pos-speed-stimme-type'>
            <Flex className='pos-stimme'>
              <Text className='stimme'>{t('smartslide.language')}</Text>
              <Flex className='gender-button-group'>
                {CONSTANTS.VOICE_SELECTIONS.map((key, index) => (
                  <Menu matchWidth={true} key={index}>
                    <MenuButton className='gender-button' onClick={() => setGenderHandler(key)}>
                      <Text className={gender === key ? "gender-text-selected" : "gender-text"}>
                        {key === 'female' ? t('smartslide.female') : t('smartslide.male')}
                      </Text>
                    </MenuButton>
                    {/* <MenuList className='menu-list'>
                      {stimmeSet[key].map((voice_nama: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | Iterable<ReactNode> | null | undefined, index: Key | null | undefined) => (
                        <MenuItem key={index} className={voice === voice_nama ? "menu-item-selected" : "menu-item"} onClick={() => _setGender(voice_nama)}>{voice_nama}</MenuItem>
                      ))}
                    </MenuList> */}
                  </Menu>
                ))}
              </Flex>
            </Flex>

            <Flex className='pos-speed'>
              <Text className='stimme'>{t('smartslide.speech_speed')}</Text>
              <Flex className='pos-speed-button-group'>
                {Object.keys(CONSTANTS.SPEED_SELECTIONS).map((speedkey, index) => (
                  <Tooltip className='tooltip' closeOnClick={false} key={speedkey} label={t(`smartslide.${speedkey}`)}>
                    <Button className={speedkey === speed ? "pos-speed-button-selected" : "pos-speed-button"} onClick={() => setSpeedHandler(speedkey)}>
                      {CONSTANTS.SPEED_SELECTIONS[speedkey as keyof typeof CONSTANTS.SPEED_SELECTIONS]}
                    </Button>
                  </Tooltip>
                ))}
              </Flex>
            </Flex>

          </Flex>
        </Flex>

        <Flex className='resolution'>
          <Text className='stimme'>{t('smartslide.video_resolution')}</Text>
          <Flex className='pos-resolution-button-group'>
            {CONSTANTS.RESOLUTION_SELECTIONS.map((key: any, index: Key | null | undefined) => (
              <Button key={index} className={key === resolution ? "pos-resolution-button-selected" : "pos-resolution-button"} onClick={() => setResolutionHandler(key)}>
                <Text className={key === resolution ? "pos-resolution-text-selected" : "pos-resolution-text"}>{t(`smartslide.${key}`)}</Text>
              </Button>
            ))}
          </Flex>
        </Flex>

        <Flex className='notizen-checkbox'>
          <Flex className='pos-icon-section-header'>
            <Text className='stimme'>{t('smartslide.notes_presentation')}</Text>
            <Tooltip className='tooltip' closeOnClick={false} label={t('smartslide.notes_hint')}>
              <Box className='pos-icon'>
                <FiInfo className='fiInfo-icon' />
              </Box>
            </Tooltip>
          </Flex>
          <Checkbox className='notizen-check' isChecked={notesIncluded} onChange={() => toggleNotesIncluded()}>
            <Text className='notizen-check-text'>{t('smartslide.select_using_notes')}</Text>
          </Checkbox>
        </Flex>

        <Flex className='spezifikationen'>
          <Text className='stimme'>{t('smartslide.topic')}</Text>
          <Textarea className='textarea' rows={3} value={specification} onChange={(e) => setSpecificationHandler(e.target.value)} placeholder={t('smartslide.topic_hint')} />
        </Flex>

        <Flex className='documentation'>
          <Flex className='documentation-info'>
            <Flex className='documentation-info-header'>
              <Flex className='documentation-info-header-title'>
                <Text className='stimme'>{t('smartslide.additional_documents')}</Text>
              </Flex>
              <Text className='documentation-info-header-comment'>{t('smartslide.additional_documents_hint1')}<span className='documentation-info-header-comment-light'>{t('smartslide.additional_documents_hint2')}</span></Text>
            </Flex>
            <Button className='documentation-upload-button' onClick={handleDocUploadButtonClick}>
              <FiUpload className='documentation-upload-button-icon' />
            </Button>
            <Input
              ref={inputDocumentRef}
              type="file"
              accept=".pdf"
              multiple
              onChange={handlePdfUpload}
              style={{ display: 'none' }}
            />
          </Flex>

          <Flex className='documentation-upload-items'>
            {pdfFiles.map((file: Blob | MediaSource, index: Key | null | undefined) => (
              <Flex className='documentation-upload-item-shell' key={index}>
                <Flex className='documentation-upload-item'>

                  <Flex className='file-meta-info'>
                    <Box className='file-icon-box'>
                      <FiFileText className='file-icon' />
                    </Box>
                    <Box className='file-meta-box'>
                      <Text className='file-name'>{file.name}</Text>
                      <Text className='file-size'>{formatFileSize(file.size)}</Text>
                    </Box>
                  </Flex>

                  <Flex className='file-options'>
                    <Button className='file-options-delete' onClick={() => removePdfFile(index)}>
                      <FiTrash2 className='file-delete-icon' />
                    </Button>
                    <Button className='file-options-view' onClick={() => handleViewPdf(file)}>
                      <Text className='file-view-text'>{t('smartslide.view')}</Text>
                    </Button>
                  </Flex>

                </Flex>
              </Flex>
            ))}
          </Flex>

        </Flex>

      </Flex>
    </Flex>
  );
};
// store.js
import { createStore } from 'redux';
import * as CONSTANTS from './constants.js';
import { CONSTANTS_RATE_MY } from './constants.js';
import { setAuthAccessObject } from 'my-api-client-package';

const initialState = {
  language: CONSTANTS.LANGUAGE_SELECTIONS[1],
  gender: CONSTANTS.VOICE_SELECTIONS[0],
  speed: Object.keys(CONSTANTS.SPEED_SELECTIONS)[1],
  resolution: CONSTANTS.RESOLUTION_SELECTIONS[1],
  specification: '',
  pptxFile: null,
  pdfFiles: [],
  notesIncluded: true,
  convertingProgress: -1,
  isConvertDisabled: true,
  fileName: '',
  slideCount: -1,
  convert: '',
  llmRun: '',
  id: '',
  thumbnailId: '',
  thumbnailUrl: '',
  sibling_scripts: {},
  editable_scripts: {},
  memory_scripts: {},
  current_slide: [-1, -1],
  edit_history: {}, // { 0: { 0 : { "history": [ '', '' ], "used": 0 } }

  // from rate-my
  token: '',
  user: {
    username: '',
    email: '',
  },
  username: ""
};

// Reducer-Funktion
function reducer(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.SET_LANGUAGE:
      return { ...state, language: action.payload };
    case CONSTANTS.SET_GENDER:
      return { ...state, gender: action.payload };
    case CONSTANTS.SET_SPEED:
      return { ...state, speed: action.payload };
    case CONSTANTS.SET_RESOLUTION:
      return { ...state, resolution: action.payload };
    case CONSTANTS.SET_SPECIFICATION:
      return { ...state, specification: action.payload };
    case CONSTANTS.SET_PPTX_FILE:
      return { ...state, pptxFile: action.payload };
    case CONSTANTS.SET_PDF_FILES:
      return { ...state, pdfFiles: action.payload };
    case CONSTANTS.SET_NOTES_INCLUDED:
      return { ...state, notesIncluded: action.payload };
    case CONSTANTS.SET_CONVERTING_PROGRESS:
      return { ...state, convertingProgress: action.payload };
    case CONSTANTS.SET_IS_CONVERT_DISABLED:
      return { ...state, isConvertDisabled: action.payload };
    case CONSTANTS.SET_FILE_NAME:
      return { ...state, fileName: action.payload };
    case CONSTANTS.SET_SLIDE_COUNT:
      return { ...state, slideCount: action.payload };
    case CONSTANTS.SET_CONVERT:
      return { ...state, convert: action.payload };
    case CONSTANTS.SET_ID:
      return { ...state, id: action.payload };
    case CONSTANTS.SET_THUMBNAIL_ID:
      return { ...state, thumbnailId: action.payload };
    case CONSTANTS.SET_THUMBNAIL_URL:
      return { ...state, thumbnailUrl: action.payload };
    case CONSTANTS.SET_SIBLING_SCRIPTS:
      return { ...state, sibling_scripts: action.payload };
    case CONSTANTS.SET_EDITABLE_SCRIPTS:
      return { ...state, editable_scripts: action.payload };
    case CONSTANTS.SET_MEMORY_SCRIPTS:
      return { ...state, memory_scripts: action.payload };
    case CONSTANTS.SET_CURRENT_SLIDE:
      return { ...state, current_slide: action.payload };
    case CONSTANTS.SET_EDIT_HISTORY:
      return { ...state, edit_history: action.payload };
    case CONSTANTS.SET_LLM_RUN:
      return { ...state, llmRun: action.payload };
    case CONSTANTS.RESET_ALL:
      return {
        ...initialState,
        token: state.token,
        user: state.user,
        username: state.username
      }
    default:
      return state;
    case CONSTANTS_RATE_MY.CHANGE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        }
      };

    case CONSTANTS_RATE_MY.CHANGE_TOKEN:
      setAuthAccessObject(JSON.stringify({ token: action.payload }));
      return {
        ...state,
        token: action.payload
      };

    case CONSTANTS_RATE_MY.SIGN_IN:
      setAuthAccessObject(JSON.stringify({ token: action.payload }));
      return {
        ...state,
        token: action.payload
      }

    case CONSTANTS_RATE_MY.USERNAME:
      return {
        ...state,
        username: action.payload.username
      };
  }
}

// Erstelle den Store
const store = createStore(reducer);
store.subscribe(() => {
  // console.log('State wurde geändert:', store.getState());
});

export default store;
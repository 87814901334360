import { apiMedimesh } from './api'
import { DOClient, baseApiParams } from 'my-api-client-package';

export const UploadMediaDo = async (file: File) => {
    const originalFileName = file.name;
    
    if (!originalFileName) {
        console.log('No file selected');
        return;
    }

    try {
        const response = await(await apiMedimesh.upload.uploadPrivateFileLegacy(
            originalFileName.toLowerCase(),
            "pptx2video",
            baseApiParams()
        )).json()

        const currentUser = await(await apiMedimesh.user.getCurrentUser(baseApiParams())).json()

        const fileName = `${currentUser.id.id}/${originalFileName}`;

        await DOClient.put(
            response.message,
            file,
            "pptx2video",
        );

        const mediaObject:any = await (await apiMedimesh.success.confirmUpload(
            { filename: fileName.toLowerCase(), mediaProperties: { } },
            baseApiParams()
        )).json()
        
        return mediaObject['id'];

    } catch (e) {
        console.error("Error:", e);
    }
};

import {
    Box,
    Button,
    ButtonGroup,
    Center,
    CloseButton,
    Flex,
    Icon,
    Stack,
    Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FiInfo } from 'react-icons/fi'

export const Notification = (props: any) => {
    const [typeColor, setTypeColor] = useState('#2C7A7B')
    const [isVisible, setIsVisible] = useState(true)

    useEffect(() => {
        switch (props.type) {
            case 'error':
                setTypeColor("#E53E3E");
                break;
            case 'success':
                setTypeColor("#2C7A7B");
                break;
            default:
                setTypeColor("#2C7A7B");
        }
    }, [props])

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();  // Calls the parent callback if provided
        }
        setIsVisible(false);
    }

    if (!isVisible) {
        return null; // Don't render anything if the notification is not visible
    }

    return (
        <Box
            as="section"
            position={'fixed'}
            right={'80px'}
            bottom={"15px"}
            zIndex={9999}
        >
            <Flex direction="row-reverse">
                <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    overflow="hidden"
                    className='notification-block'
                    maxW={"570px"}
                    minW={"450px"}
                >
                    <Center display={{ base: 'none', sm: 'flex' }} bg={typeColor} px="5">
                        <Icon as={FiInfo} boxSize="10" color="white" />
                    </Center>
                    <Stack direction="row" p="4" spacing="3" flex="1" bg={'#FFF'}>
                        <Stack spacing="2.5" flex="1">
                            <Stack spacing="1">
                                <Text textStyle="sm" fontWeight="medium">
                                    {props.heading}
                                </Text>
                                <Text textStyle="sm" color="fg.muted">
                                    {props.message}
                                </Text>
                            </Stack>
                            <ButtonGroup variant="text" size="sm" spacing="3">
                                <Button colorScheme="gray" onClick={handleClose}>{props.buttonText}</Button>
                            </ButtonGroup>
                        </Stack>
                        <CloseButton transform="translateY(-6px)" onClick={handleClose} />
                    </Stack>
                </Flex>
            </Flex>
        </Box>
    )
}

import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { FiInfo } from 'react-icons/fi';
import './CustomAlert.scss';
import { Box } from '@chakra-ui/react';
import { MutableRefObject } from 'react';

interface CustomAlertProps {
    leastDestructiveRef: MutableRefObject<any>;
    onClose: () => void;
    isOpen: boolean;
    topbuttonfunction: () => void;
    bottombuttonfunction: () => void;
    content: any[],
    level: 'info' | 'warning'
}

export const CustomAlertDialog: React.FC<CustomAlertProps> = ({
    leastDestructiveRef,
    onClose,
    isOpen,
    topbuttonfunction,
    bottombuttonfunction,
    content,
    level
}) => {
    return (
        <AlertDialog
            motionPreset='slideInBottom'
            leastDestructiveRef={leastDestructiveRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered
            closeOnOverlayClick={false}
        >
            <AlertDialogOverlay />
            <AlertDialogContent className='alert'>
                <AlertDialogBody className='alert-body'>
                    <Box className={`alert-icon-box-${level}`}>
                        <FiInfo className='alert-icon' />
                    </Box>
                    <Box className='alert-content'>
                        <Box className='alert-content-1'>
                            {content[0]}
                        </Box>
                        <Box className='alert-content-2'>
                            {content[1]}
                        </Box>
                    </Box>

                    <Box className='alert-buttons'>
                        <Box ref={leastDestructiveRef} onClick={topbuttonfunction} className={`alert-top-button-${level}`}>
                            {content[2]}
                        </Box>
                        <Box onClick={bottombuttonfunction} className='alert-bottom-button'>
                            {content[3]}
                        </Box>
                    </Box>
                </AlertDialogBody>
            </AlertDialogContent>
        </AlertDialog>
    );
};
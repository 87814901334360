import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import './VideoGeneratingPopUp.scss';
import { Box } from '@chakra-ui/react';
import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import generatingImage from '../../assets/img/GeneratingPopup.png';

interface VideoGeneratingPopUp {
    leastDestructiveRef: MutableRefObject<any>;
    onClose: () => void;
    isOpen: boolean;
}

export const VideoGeneratingPopUp: React.FC<VideoGeneratingPopUp> = ({
    leastDestructiveRef,
    onClose,
    isOpen
}) => {
    const { t } = useTranslation();
    return (
        <AlertDialog
            motionPreset='slideInBottom'
            leastDestructiveRef={leastDestructiveRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered
            closeOnOverlayClick={false}
        >
            <AlertDialogOverlay />
            <AlertDialogContent className='alert-generating'>
                <AlertDialogBody className='alert-body-generating'>
                    <Box className='alert-body-generating-product-image-box'>
                        <img src={generatingImage} alt='Generating' className='generating-image' />
                    </Box>
                    <Box className='alert-text-box'>
                        <Box className='alert-content-title'>
                            {t('smartslide.make_video_waiting_title')}
                        </Box>
                        <Box>
                            <p className='alert-content-content'>{t('smartslide.make_video_waiting_info_1')}</p>
                            <br />
                            <p className='alert-content-highlight'>{t('smartslide.make_video_waiting_info_2')}</p>
                            <br />
                            <p className='alert-content-content'>{t('smartslide.make_video_waiting_info_3')}</p>
                        </Box>
                    </Box>
                </AlertDialogBody>
            </AlertDialogContent>
        </AlertDialog>
    );
};